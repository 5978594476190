<div class="row" *ngIf="style2022$ | async">
  <div *ngIf="aI.ShowFullProfile" class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>
<!-- Profile -->
<div class="row" [ngClass]="aI.ShowFullProfile ? 'profile-wrapper' : 'profile-wrapper-minimal'"  *ngIf="user">
  <app-user-card [showFullProfile]="aI.ShowFullProfile"  [user]="user" [numberFormats]="numberFormats" [languageTypes]="languageTypes" [currencyTypes]="currencyTypes"></app-user-card>
</div>

<div class="row" *ngIf="user && aI.ShowFullProfile">
  <!-- My Team -->
  <div class="col-xs-12 col-sm-6" >
    <app-team-card [teamMembers]="teamMembers"></app-team-card>
  </div>
  <!-- My Privileges -->
  <div class="col-xs-12 col-sm-6">
    <app-privileges-card [privileges]="user.privileges"></app-privileges-card>
  </div>
</div>
<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>{{trans.trans.costLiteral.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'cost'" [isTranslationV2]="true" [transObj]="trans.trans.costLiteral"
				(callback)="getTranslations()"></app-editTranslation>
			</div>
		</div>
		<div class='col-xs-6'>
			<button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="funcShowAddCost()" matTooltipPosition="above">
				<i class="fa fa-plus"></i>{{trans.trans.addNew.value}}</button>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input [(ngModel)]="strSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch=''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="dropdown" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='row'>
	<div class='col-xs-12'>
		<p-progressBar *ngIf="!loaded" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
		<p-table #tableSolutionList [value]="costs" [columns]="cols" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{trans.trans[col.field].value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'cost'" [isTranslationV2]="true"
						[transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-cost>
				<tr>
					<td (click)="editSolutionCosts(cost)">
						<span class="clickable">{{cost.name}}</span>
						<i *ngIf="cost.description && !(style2022$ | async)" [matTooltip]='cost.description' class='fa fa-info-circle'></i>
						<span *ngIf="cost.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="cost.description">
							info
						</span>
					</td>
					<td>{{ cost.displayUnitPrice ? precisionOf5 ? (cost.cost | number:'1.5-5') : cost.cost : '-' }}</td>
					<td>
						<span *ngIf="can_edit" class='keywest_icon_group pull-right'>
							<span><i (click)='editSolutionCosts(cost)' [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
							<span>
								<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteSolutionCost(cost.id)'></app-are-you-sure>
							</span>
						</span>
						<span *ngIf="!can_edit" class='keywest_icon_group pull-right'>
							<span><i (click)='funcToggleView(cost)' class='fa fa-eye clickable pull-right' [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCost' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-ae-costs *ngIf="toggleAddCost && can_edit" [mode]='mode' [solutionID]="solutionID" [costFormObj]='costFormObj' [term]="term" (callback)='getSolutionCostList(); toggleAddCost = false'></app-ae-costs>
	<app-r-costs *ngIf="toggleAddCost && !can_edit" [costFormObj]='costFormObj'></app-r-costs>
</p-sidebar>

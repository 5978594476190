import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { CommonService } from "@data/services/common/common.service";
import { ValuepropService } from "@data/services/valueprop/valueprop.service";
import { NotificationService } from "@services/notification.service";
import { ValueProp } from "@shared/models/value-prop.model";
import { Subject } from "rxjs";
import { debounceTime, finalize, take, takeUntil } from "rxjs/operators";
import { TranslationsV2Service } from "@data/services/translationsv2/translationsv2.service";
import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErrorMessagesService } from "@services/error-messages.service";
import { CollaborationTranslations } from "./collaboration.translation";
import { Collaboration, CreateCollaborationPayload, UpdateCollaborationPayload } from "@shared/models/collaboration.models";
import { CollaborationServices } from "@data/services/collaboration/collaboration.service";
import { DiscoveryQuestion } from "@data/services/valueprop/models/discovery-question.model";
import { ConversationWorkflow } from "@data/services/workflow/models/workflow.interface";
import { ConfigurableWorkflowService } from "@data/services/workflow/configurable-workflow.service";
import { CustomerShareServices } from "@data/services/customer_share/share.service";

interface AssetListItem {
  account_asset_template_id: number;
  artifact_name: number;
  sequence: number;
  selected?: boolean;
  name?: string;
}

@Component({
	selector: 'app-collaboration',
	templateUrl: './collaboration.component.html',
	styleUrls: ['./collaboration.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CollaborationComponent implements OnInit, OnDestroy {

	@Input('data') valueProp: ValueProp;
	@Input() showHelp: boolean;
	@Input() showTranslate = false;
	@Input() embedded = false;
	@Input() fromRepSimple = false;

	@Output() callbackBack = new EventEmitter();

	ngUnsubscribe = new Subject();

  savingCollaboration: boolean = false;
	collaborationsLoader: boolean = false;
	header_color: string;
	collaborations: Collaboration[] = [];
  discoveryQuestions: DiscoveryQuestion[] = [];
  assets: AssetListItem[] = [];
	listView: boolean = true;
  emailView: boolean = false;
  collaborationView: boolean = false;
  editingExisting: boolean = false;
  lightTheme: boolean = false;
  search: string = '';

	errorDict = new Map();

	nameEmailForm: FormGroup;
  collaboration: Collaboration;
  selectedCollaboration : Collaboration;
  selectedAsset: AssetListItem;

	constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    public trans: CollaborationTranslations,
    private translationService: TranslationsV2Service,
    private collaborationServices: CollaborationServices,
    private fb: FormBuilder,
    private errorMessageService: ErrorMessagesService,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private customerShareService: CustomerShareServices,
  ) {}

	ngOnInit(): void {
    this.header_color = localStorage.getItem('header_color');

    this.setEmptyForm();
    this.setEmptyCollaboration();

    this.errorDict = new Map(this.errorMessageService.getFormErrors(this.nameEmailForm.controls));

    this.nameEmailForm.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.nameEmailForm.controls));
    });

    this.collaborationsLoader = true;
    this.loadCollaborations();
    this.loadDiscoveryQuestions();

    this.configurableWorkflowService.selectedConversationWorkflow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((wf: ConversationWorkflow) => {
      if (wf) {
        this.loadAssets(wf);
      } else {
        this.loadAssets();
      }
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.lightTheme = document.getElementById('custom-theme') !== null;

  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setEmptyCollaboration() {
    this.collaboration = {
      user_name: '',
      email: '',
      expires: '',
      value_prop_id: this.valueProp.id,
      user_id: '',
      benefits_editable: '0',
      benefits_editable_boolean: false,
      assumptions_editable: '0',
      assumptions_editable_boolean: false,
      account_asset_template_id: '0',
      asset_expanded: false,
      discovery_questions_enabled: '0',
      discovery_questions_enabled_boolean: false,
      discovery_expanded: false,
      first_name: '',
      last_name: '',
      link: '',
      password: '',
    };
  }

  setEmptyForm() {
    this.nameEmailForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      expires: ['', Validators.required],
    });

    this.nameEmailForm.controls.name.enable();
    this.nameEmailForm.controls.email.enable();
  }

  loadDiscoveryQuestions() {
		this.valuepropService
      .getDiscoveryQuestions(this.valueProp.id, '46')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: { result: DiscoveryQuestion[] }) => {
        this.discoveryQuestions = response.result.sort((a, b) => +a.sequence - +b.sequence);
        this.discoveryQuestions.map((question) => {
          question.editing = false;
          question.edited = false;
        });
      });
	}

  consoleChange() {
    console.log('change');
  }

  loadAssets(wf = null) {
    let call;
    if (wf) {
      call = this.valuepropService.readValuePropAssetTemplatesForWfs(this.valueProp.id, wf.id);
    } else {
      call = this.valuepropService.readValuePropAssetTemplatesForWfs(this.valueProp.id);
    }
    call.pipe(take(1)).subscribe((result) => {
      if (result.result) {
        this.assets = result.result;
      }
    });
  }

	loadCollaborations(selectedHash = null) {
		const valuePropId = this.valueProp.id;
		this.collaborationServices
			.getCollaborations(valuePropId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((response) => {
				if (response.result) {
					this.collaborations = response.result.collaborations;
          if (selectedHash) {
            this.selectedCollaboration = this.collaborations.find(c => c.hash === selectedHash);
            this.changeView('email');
          }
				}
			});
	}

	saveCollaboration(): void {
    if (this.nameEmailForm.invalid) {
      this.nameEmailForm.markAllAsTouched();
      return;
    } else if ( this.collaboration.asset_enabled_boolean && this.collaboration.account_asset_template_id === '0' ) {
      this.notificationService.error(this.trans.trans.assetRequired.value, false);
      return;
    }

    this.savingCollaboration = true;
    let basePayload = {
      benefits_editable: this.collaboration.benefits_editable_boolean ? '1' : '0',
      assumptions_editable: this.collaboration.assumptions_editable_boolean ? '1' : '0',
      account_asset_template_id: this.collaboration.account_asset_template_id,
      discovery_questions_enabled: this.collaboration.discovery_questions_enabled_boolean ? '1' : '0',
      expires: this.nameEmailForm.controls.expires.value
    };

    if ( this.collaboration.id ) {
      // Editing
      let payload: UpdateCollaborationPayload = {
        ... basePayload,
        id: this.collaboration.id
      };

      this.collaborationServices
        .updateCollaboration(this.valueProp.id, payload)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.savingCollaboration = false))
        )
        .subscribe((response: any) => {
          if (response.result.success === false) {
            this.notificationService.error(response.result.message, false);
          } else {
            if (this.collaboration.discovery_questions_enabled_boolean) {
              this.saveDiscoveryQuestions();
            }
            this.loadCollaborations();
            this.notificationService.success(this.trans.trans.saveSuccess.value + this.collaboration.user_name, false);
            this.setEmptyForm();
            this.setEmptyCollaboration();
            this.changeView('list');
          }
        });

    } else {
      // Creating New
      let payload: CreateCollaborationPayload = {
        ... basePayload,
        email: this.nameEmailForm.controls.email.value,
        first_name: this.nameEmailForm.value.name.split(' ')[0],
        last_name: this.nameEmailForm.value.name.split(' ')[1],
      };

      this.collaborationServices
        .createCollaboration(this.valueProp.id, payload)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.savingCollaboration = false))
        )
        .subscribe((response: any) => {
          if (response.result.success === false) {
            this.notificationService.error(response.result.message, false);
          } else {
            if (this.collaboration.discovery_questions_enabled_boolean) {
              this.saveDiscoveryQuestions();
            }
            this.loadCollaborations(response.result.collaboration.hash);
            this.notificationService.success(this.trans.trans.saveSuccess.value + this.collaboration.user_name, false);
            this.setEmptyForm();
            this.setEmptyCollaboration();
          }
        });
    }
	}

  onCancel() {
    this.setEmptyForm();
    this.setEmptyCollaboration();
    this.changeView('list');
  }

  editCollaboration(collaboration) {
    this.nameEmailForm.controls.name.setValue(collaboration.user_name);
    this.nameEmailForm.controls.name.disable();

    this.nameEmailForm.controls.email.setValue(collaboration.email);
    this.nameEmailForm.controls.email.disable();

    this.nameEmailForm.controls.expires.setValue(new Date(collaboration.expires));

    this.collaboration = collaboration;

    this.collaboration.discovery_questions_enabled_boolean = this.collaboration.discovery_questions_enabled === '1';
    this.collaboration.discovery_expanded = this.collaboration.discovery_questions_enabled_boolean;
    this.collaboration.asset_enabled_boolean = this.collaboration.account_asset_template_id !== '0';
    this.collaboration.benefits_editable_boolean = this.collaboration.benefits_editable === '1';
    this.collaboration.assumptions_editable_boolean = this.collaboration.assumptions_editable === '1';
    this.selectedAsset = this.assets.find(asset => asset.account_asset_template_id === +this.collaboration.account_asset_template_id)
    this.collaboration.asset_expanded = this.collaboration.asset_enabled_boolean;
 
    this.changeView('collaboration')
  }

  setSelectedAsset() {
    this.collaboration.account_asset_template_id = '0';
    if (this.selectedAsset) {
      this.collaboration.account_asset_template_id = this.selectedAsset.account_asset_template_id.toString()
    }
    this.collaboration.asset_enabled_boolean = this.selectedAsset !== null;
  }

  saveDiscoveryQuestions(): boolean {
    let payload: { discovery_questions: DiscoveryQuestion[] };
    const discovery_questions = [];
    if (this.discoveryQuestions && this.discoveryQuestions.length) {
      this.discoveryQuestions.forEach((question) => {
        if ( question.edited ) {
          discovery_questions.push({
            account_discovery_question_id: question.account_discovery_question_id,
            answer: question.answer,
          });
        }
      });
    }

    payload = { discovery_questions: discovery_questions };

    this.valuepropService
      .putDiscoveryQuestions(this.valueProp.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          return true;
        } else {
          return false;
        }
      });

    return false;
  }

  deleteCollaboration(collaboration) {
    this.customerShareService
        .deleteExternalShare(this.valueProp.id, collaboration.hash)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result && response.result.success !== false) {
            this.notificationService.success(response.result.message, false);
          } else if (response.result.success === false) {
            this.notificationService.error(response.result.message, false);
          }
          this.loadCollaborations();
        });
  }

  public goToEmail(collaboration: Collaboration) {
    this.selectedCollaboration = collaboration;
    this.changeView('email'); 
  }

  public changeView(view: 'list'|'collaboration'|'email') {
    switch(view) {
      case 'list':
      this.listView = true;
      this.collaborationView = false;
      this.emailView = false;  
      break;
      case 'collaboration': 
      this.listView = false;
      this.collaborationView = true;
      this.emailView = false; 
      break;
      case 'email': 
      this.listView = false;
      this.collaborationView = false;
      this.emailView = true; 
      break;
    }
  }

  addCollaboration() {
    this.setEmptyCollaboration();
    this.nameEmailForm.reset();
    this.nameEmailForm.patchValue({ name: '', email: '', expires: '' });
    this.nameEmailForm.controls.name.enable();
    this.nameEmailForm.controls.email.enable();
    this.selectedAsset = null;
    this.changeView('collaboration');
  }

  toggleExpandOnClick() {
    if(this.collaboration.asset_enabled_boolean) {
      this.collaboration.asset_expanded = true;
    } else {
      this.collaboration.asset_expanded = false;
      this.selectedAsset = null;
      this.collaboration.account_asset_template_id = '0';
    }
  }

  public closeSidebar() {
    this.callbackBack.emit();
  }
}
<form name="formAddEditModel" #formAddEditModel="ngForm">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{sidebar_title.value}}
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditModel.controls.name'>
						<label for="name" class="sidebar_label">{{trans.trans.model_name.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="'ae_list'" [isTranslationV2]="true"
							[transObj]="trans.trans.model_name" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<input type="text" class="form-control" ret-data='Name' [(ngModel)]="frmSolutionObj.name" name="name" placeholder="{{trans.trans.model_name.value}}" required>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditModel.controls.solution_category_id'>
						<label for="name" class="sidebar_label">{{trans.trans.categories.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="'ae_list'" [isTranslationV2]="true"
							[transObj]="trans.trans.categories" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<p-multiSelect class='form-control' [options]="categoryList" ret-data='Categories' name='solution_category_id' [(ngModel)]="selectedCategories" optionLabel="category"></p-multiSelect>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditModel.controls.term'>
						<label class="sidebar_label">Term</label>
						<p-dropdown appendTo="body" [autoDisplayFirst]="false" class='keywest_dropdown' [options]="termList" name='term' [(ngModel)]="selectedTerm" optionLabel="name" dataKey="term" [required]="true"></p-dropdown>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditModel.controls.avg_deployment_time'>
						<label for="name" class="sidebar_label">{{trans.trans.average_dep_time.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="'ae_list'" [isTranslationV2]="true"
							[transObj]="trans.trans.average_dep_time" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<input type="text" class="form-control" ret-data='Average Deployment Time' [(ngModel)]="frmSolutionObj.avg_deployment_time" name="avg_deployment_time" placeholder="{{trans.trans.average_dep_time.value}}" [required]="true">
					</div>
				</div>

				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditModel.controls.description'>
						<label for="name" class="sidebar_label">{{trans.trans.description.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="'ae_list'" [isTranslationV2]="true"
							[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control" ret-data='Description' [(ngModel)]="frmSolutionObj.description" name="description" placeholder="{{trans.trans.description.value}}"></textarea>
					</div>
				</div>

			</div>
		</div>
		<div class='btn_container' [ngClass]="{ 'xplatform-btn-container-adjustment': isUsingXPlatformCrm }">
			<div class='row'>
				<div class="col-md-12">
					<input type='hidden' name='id' [(ngModel)]="frmSolutionObj.id">
					<input type='hidden' name='typical_deployment_type' [(ngModel)]="frmSolutionObj.typical_deployment_type">
					<input type='hidden' name='quantity_enabled' [(ngModel)]="frmSolutionObj.quantity_enabled">
					<app-btn-save *ngIf="mode == 'add'" [form]='formAddEditModel' (callback)='insertSolution(formAddEditModel)' [text]='trans.trans.save.value' class='btn btn_customizable pull-right xf-continue-button'></app-btn-save>
					<app-btn-save *ngIf="mode == 'edit'" [form]='formAddEditModel' (callback)='updateSolution(formAddEditModel)' [text]='trans.trans.update.value' class='btn btn_customizable pull-right xf-continue-button'></app-btn-save>
					<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.areYouSure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
				</div>
			</div>
		</div>
	</div>
</form>

<div class="sidebar_wrapper">
	<div *ngIf="listView" class="sidebar_container" id="collaboration-container">
		<div class="keywest_header">
      <div class="row">
        <div class="col-xs-12">
          <div class="collab-title">
            {{ trans.trans.externalCollaboration.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.externalCollaboration"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <app-are-you-sure
            [message]="trans.trans.areYouSure.value"
            text=""
            style="float: right; font-size: 20px;"
            (callbackYes)="closeSidebar()"
          ></app-are-you-sure>
          </div>
        </div>
        <div class="row">
          <div style="margin: 0 20px;">
            <table id="collaborations" class="table custom-table" style="width: 100%; max-height: 400px; overflow-y: scroll; margin-bottom: 140px">
              <thead>
                <tr>
                  <th>
                    {{ trans.trans.who.value }} ( {{ collaborations.length }} )
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.who"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>

                  <th>
                    <button
                      (click)="addCollaboration()"
                      class="pull-right btn btn_customizable"
                      style="max-width: 200px; font-weight: normal !important"
                    >{{ trans.trans.addCollaboration.value }}</button>
                  </th>
                </tr>
              </thead>

              <tbody *ngIf="collaborations && collaborations !== null && collaborations.length > 0">
                <tr *ngFor="let collaboration of collaborations | filter: search:'user_name'" class="grid-row">
                  <td>
                    <a class="link collab-sub-item-text" (click)="editCollaboration(collaboration)">{{ collaboration.user_name }}</a>
                    <br/>
                    <a href="mailto:{{ collaboration.email }}" style="font-size: 70%">{{ collaboration.email }}</a
                    ><br />
                  </td>

                  <td>
                    <div class="pull-right" style="display: flex;">
                        <span class="clickable" style="margin:0 10px">
                          <i class="fa fa-envelope" (click)="goToEmail(collaboration)" [matTooltip]="'Email'" matTooltipPosition="above"></i>
                        </span>
                      <span class="clickable">
                        <app-are-you-sure
                          [matTooltip]="trans.trans.delete.value"
                          matTooltipPosition="above"
                          [message]="trans.trans.areYouSure.value"
                          text=""
                          style="font-size: 18px;"
                          icon="fieldset_icon fa fa-trash"
                          (callbackYes)="deleteCollaboration(collaboration)"
                        ></app-are-you-sure>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="(collaborations === null || collaborations.length === 0) && !collaborationsLoader" class="m-t-md col-sm-12">
            <div class="searchTableContainer text-center">
              <h4>
                {{ trans.trans.noCollaborationsFound.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.noCollaborationsFound"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </h4>
            </div>
          </div>
        </div>

      </div>
    </div>
	</div>
	<div *ngIf="collaborationView" class="sidebar_container" id="collaboration-container">
		<div class="keywest_header">
      <div class="row">
        <div class="col-xs-12">
          <app-are-you-sure
          [message]="trans.trans.areYouSure.value"
          text=""
          style="float: right; font-size: 20px;"
          (callbackYes)="closeSidebar()"
        ></app-are-you-sure>
          <div>
            <app-are-you-sure
            [message]="trans.trans.areYouSure.value"
            [text]="trans.trans.back.value"
            icon=""
            class="link"
            (callbackYes)="changeView('list')"
          ></app-are-you-sure>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="collab-title">
            {{ trans.trans.externalCollaboration.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.externalCollaboration"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
				<div class="col-xs-12">
          <div class="collab-description">
            {{ trans.trans.addDetails.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.addDetails"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>
    </div>
    <div class="collab-subtitle">
      {{ trans.trans.shareWith.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.shareWith"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div class="row">
      <div>
        <div class="searchTableContainer" [formGroup]="nameEmailForm">
          <table class="table custom-table" style="border-collapse: collapse;">
            <thead></thead>
            <tbody>
              <tr class="grid-row">
                <td colspan="3" style="border-top: none;">
                  <app-error-input-wrapper [errorMessage]="errorDict.get('name')" [showOnTouched]="nameEmailForm.controls.name.touched">
                  <label class="collab-label labelsRequired" for="name">{{ trans.trans.name.value }}</label>
                  <input 
                    type="text" 
                    pInputText 
                    formControlName="name"
                    placeholder="{{ trans.trans.addName.value }}" 
                    class="form-control" 
                    autocomplete="off"
                  />
                  </app-error-input-wrapper>
                </td>
              </tr>

              <tr class="grid-row">
                <td colspan="3" style="border-top: none;">
                  <app-error-input-wrapper [errorMessage]="errorDict.get('email')" [showOnTouched]="nameEmailForm.controls.email.touched">
                    <label class="collab-label labelsRequired" for="email">{{ trans.trans.email.value }}</label>
                    <input 
                      type="text" 
                      pInputText 
                      formControlName="email"
                      placeholder="{{ trans.trans.addEmail.value }}" 
                      class="form-control" 
                      autocomplete="off" 
                    />
                  </app-error-input-wrapper>
                </td>
              </tr>

              <tr class="grid-row">
                <td colspan="3" style="border-top: none;">
                  <label for="expires" class="labelsRequired">{{ trans.trans.expirationDate.value }}</label>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.expirationDate"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <app-error-input-wrapper [errorMessage]="errorDict.get('expires')" [showOnTouched]="nameEmailForm.controls.expires.touched">
                    <p-calendar 
                      placeholder="{{ trans.trans.expirationPlaceholder.value }}" 
                      [style]="{'width':'100%'}" 
                      inputStyleClass="collab-calendar"
                      appendTo="body" 
                      formControlName="expires" 
                      [showIcon]="true" 
                      icon="fa fa-calendar" 
                      id="collab-expires"
                    ></p-calendar>
                  </app-error-input-wrapper>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div class="searchTableContainer">
          <table class="table custom-table" style="border-collapse: collapse;">
            <thead></thead>
            <tbody>
              <tr style="border-bottom: solid 1px #D0D5DD">
                <th style="width: 80%; border-top: none;" class="collab-heading">
                  {{ trans.trans.whatToShare.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.whatToShare"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </th>
                <th style="width: 20%; text-align: center !important; border-top: none; " class="collab-heading">
                  {{ trans.trans.include.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.include"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </th>
              </tr>
              <tr class="collab-tr" *ngIf="discoveryQuestions && discoveryQuestions.length > 0">
                <td class="collab-td collab-td-padding ">
                  <span class="icon-background" (click)="collaboration.discovery_expanded = !collaboration.discovery_expanded">
                    <img *ngIf="!lightTheme" src="assets/images/icons/chevron-down-full.svg"/>
                    <img *ngIf="lightTheme" src="assets/images/icons/chevron-down-full-xf.svg"/>
                  </span>
                  <h1 class="breadcrumb-title mr-0 clickable collab-heading">
                    {{ trans.trans.discoveryQuestions.value }}
                  </h1>
                </td>
                <td class="collab-td-padding " style="text-align: center; border-top: solid 1px #D0D5DD;">
                  <p-checkbox [binary]="true" class="collab-checkbox" [(ngModel)]="collaboration.discovery_questions_enabled_boolean"></p-checkbox>
                </td>
              </tr>
              <tr colspan="3" *ngFor="let question of discoveryQuestions" style="background-color: #FCFCFD;">
                <td *ngIf="collaboration.discovery_expanded" colspan="3" style="width: 100%;" class="collab-sub-item-text">
                  <div class="col-xs-12 collab-sub-item-padding-light">
                    <label class="marg-v collab-sub-item-text"
                      >
                      {{ question.name }}
                      <span [matTooltip]="question.description">
                        <img *ngIf="question.description" src="assets/images/icons/help-circle.svg"/>
                      </span>
                    </label>
                    <span [ngSwitch]="question.question_type_id">
                      <div *ngSwitchCase="1">
                        <input type="text" [(ngModel)]="question.answer" (ngModelChange)="question.edited = true" class="form-control"/>
                      </div>
                      <div *ngSwitchCase="2">
                        <textarea [(ngModel)]="question.answer" rows="3" class="form-control" (ngModelChange)="question.edited = true"></textarea>
                      </div>
                      <div *ngSwitchCase="3">
                        <p-inputSwitch [(ngModel)]="question.answer" (ngModelChange)="question.edited = true"></p-inputSwitch>
                      </div>
                      <p *ngSwitchDefault></p>
                    </span>
                  </div>
                </td>
              </tr>
              <tr class="collab-tr ">
                <td class="collab-td collab-td-padding">
                  <span class="icon-background">
                    <img src="assets/images/icons/blank.svg"/>
                  </span>
                  <h1 class="breadcrumb-title mr-0 clickable collab-heading">
                    {{ trans.trans.outcomes.value }}
                  </h1>
                </td>
                <td class="collab-td-padding " style="text-align: center; border-top: solid 1px #D0D5DD;">
                  <p-checkbox [binary]="true" class="collab-checkbox" [(ngModel)]="collaboration.benefits_editable_boolean"></p-checkbox>
                </td>
              </tr>
              <tr class="collab-tr">
                <td class="collab-td collab-td-padding ">
                  <span class="icon-background">
                    <img src="assets/images/icons/blank.svg"/>
                  </span>
                  <h1 class="breadcrumb-title mr-0 clickable collab-heading">
                    {{ trans.trans.keyAssumptions.value }}
                  </h1>
                </td>
                <td class="collab-td-padding " style="text-align: center; border-top: solid 1px #D0D5DD;">
                  <p-checkbox [binary]="true" class="collab-checkbox" [(ngModel)]="collaboration.assumptions_editable_boolean"></p-checkbox>
                </td>
              </tr>
              <tr class="collab-tr">
                <td class="collab-td collab-td-padding">
                  <span class="icon-background" (click)="collaboration.asset_expanded = !collaboration.asset_expanded">
                    <img *ngIf="!lightTheme" src="assets/images/icons/chevron-down-full.svg"/>
                    <img *ngIf="lightTheme" src="assets/images/icons/chevron-down-full-xf.svg"/>
                  </span>
                  <h1 class="breadcrumb-title mr-0 clickable collab-heading">
                    {{ trans.trans.assets.value }}
                  </h1>
                </td>
                <td class="collab-td-padding " style="text-align: center; border-top: solid 1px #D0D5DD;">
                  <p-checkbox [binary]="true" class="collab-checkbox" [(ngModel)]="collaboration.asset_enabled_boolean" (onChange)="toggleExpandOnClick()"></p-checkbox>
                </td>
              </tr>
              <tr colspan="3" *ngFor="let asset of assets; let i = index" style="background-color: #FCFCFD;">
                <td *ngIf="collaboration.asset_expanded" class="collab-sub-item-text collab-sub-item-padding">
                  {{ asset.artifact_name }}
                </td>
                <td *ngIf="collaboration.asset_expanded" class="collab-td-padding" style="text-align: center;">
                  <p-radioButton 
                    (onClick)="setSelectedAsset()" 
                    name="assetGroup"
                    [inputId]="asset.sequence.toString()"
                    [value]="asset" 
                    [(ngModel)]="selectedAsset" 
                    class="collab-checkbox">
                  </p-radioButton>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="border-top: none;">
                  <button (click)="saveCollaboration()" class="btn btn_customizable" style="width: 100%;">
                   <span *ngIf="collaboration.id">{{ trans.trans.confirm.value }}</span> 
                   <span *ngIf="!collaboration.id">{{ trans.trans.continue.value }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
	</div>
  <!-- Email -->
  <div *ngIf="emailView" class="sidebar_container">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-12">
          <app-are-you-sure
          [message]="trans.trans.loseEmailProgress.value"
          text=""
          style="float: right; font-size: 20px;"
          (callbackYes)="closeSidebar()"
        ></app-are-you-sure>
          <div>
              <app-are-you-sure
                [message]="trans.trans.loseEmailProgress.value"
                [text]="trans.trans.back.value"
                icon=""
                class="link"
                (callbackYes)="changeView('list')"
              ></app-are-you-sure>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="collab-title">
            {{ trans.trans.shareWithCustomer.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.shareWithCustomer"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
				<div class="col-xs-12">
          <div class="collab-description">
            {{ trans.trans.addTheDetails.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.addTheDetails"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>
    </div>
    <div>
      {{ trans.trans.emailCustomer.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.emailCustomer"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <app-collaboration-email [collaboration]="selectedCollaboration"></app-collaboration-email>
  </div>
</div>
<form name="formAddEditCost" #formAddEditCost="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="sidebar_title"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.name">
            <label for="name" class="sidebar_label"
              >{{ trans.trans.costType.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.costType"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              [(ngModel)]="costFormObj.name"
              [attr.ret-data]="trans.trans.costType.value"
              required
              [disabled]="can_edit"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label for="description" class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" id="description" [(ngModel)]="costFormObj.description" class="form-control" [disabled]="can_edit"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.accrual_type_id">
            <label for="accrual_type_id" class="sidebar_label"
              >{{ trans.trans.accrualType.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.accrualType"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <select
              class="form-control"
              [(ngModel)]="costFormObj.accrual_type_id"
              id="accrual_type_id"
              name="accrual_type_id"
              [attr.ret-data]="trans.trans.accrualType.value"
              type="text"
              required
              (change)="accrualChange()"
              [disabled]="can_edit"
            >
              <option disabled="disabled">{{ trans.trans.chooseOne.value }}</option>
              <option *ngFor="let accrualType of accrualTypes" [value]="accrualType.id">
                {{ accrualType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="optional-fields">
          <div *ngIf="optionalFields.cost" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.cost">
              <label for="cost" class="sidebar_label"
                >{{ trans.trans.unitPrice.value }} ($)
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.unitPrice"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="cost"
                name="cost"
                [rangeInput]="[allowNegatives ? undefined : 0]"
                [(rangeInputModel)]="costFormObj.cost"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [attr.ret-data]="trans.trans.unitPrice.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.costPerYear" class="row">
            <div *ngFor="let term of termIndexArray" class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls['yr' + term + '_costs']">
              <label class="sidebar_label"
                >{{ trans.trans['year' + term]?.value }} ($)
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans['year' + term]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                [id]="'yr' + term + '_costs'"
                [(ngModel)]="costFormObj['yr' + term + '_costs']"
                [name]="'yr' + term + '_costs'"
                [attr.ret-data]="trans.trans['year' + term]?.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.growthRate" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.rate">
              <label for="growthRate" class="sidebar_label"
                >{{ trans.trans.yoyGrowthRate.value }} (%)
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.yoyGrowthRate"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="growthRate"
                name="rate"
                [rangeInput]="[allowNegatives ? undefined : 0]"
                [(rangeInputModel)]="costFormObj.rate"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [attr.ret-data]="trans.trans.yoyGrowthRate.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div class="row" *ngIf="optionalFields.scaledBy && dropdownValues && dropdownValues.readAccountAggregatedFactors && dropdownValues.readAccountAggregatedFactors.length">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ scaledByLabel?.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.scaledByLabel"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <p-dropdown
                appendTo="body"
                class="keywest_dropdown"
                [autoDisplayFirst]="false"
                optionLabel="name"
                [options]="dropdownValues.readAccountAggregatedFactors"
                name="scaleFactor"
                [(ngModel)]="costFormObj.scaleFactor"
                [filter]="true"
                filterBy="name"
                [disabled]="can_edit"
                panelStyleClass="dropdown-items-fixed-width dropdown-items-w500"
              ></p-dropdown>
            </div>
          </div>
          <div *ngIf="optionalFields.scaledBy && costFormObj.scaleFactor && costFormObj.scaleFactor.id !== '2' && costFormObj.scaleFactor.id !== '1'">
            <div class="row">
              <div class="col-xs-1">&nbsp;</div>
              <div class="col-md-4">
                <label for="name" class="sidebar_label"
                  >{{ trans.trans.scalesHow.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.scalesHow"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <p-dropdown
                  appendTo="body"
                  name="scaleHow"
                  optionLabel="name"
                  [options]="scalesHow"
                  [filter]="true"
                  filterBy="name"
                  [autoDisplayFirst]="false"
                  [(ngModel)]="costFormObj.scaleHow"
                  class="keywest_dropdown"
                  [disabled]="can_edit"
                ></p-dropdown>
              </div>

              <div class="col-xs-4" *ngIf="+costFormObj.scaleHow?.id === 2">
                <label for="name" class="sidebar_label"
                  >{{ trans.trans.ratio.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.ratio"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <input type="text" [attr.ret-data]="trans.trans.ratio.value" class="form-control" [(ngModel)]="costFormObj.ratio" name="ratio" required [disabled]="can_edit" />
              </div>

              <div class="col-xs-4" *ngIf="+costFormObj.scaleHow?.id === 3">
                <label for="name" class="sidebar_label"
                  >{{ trans.trans.formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.formula"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <input
                  type="text"
                  [attr.ret-data]="trans.trans.formula.value"
                  class="form-control"
                  [(ngModel)]="costFormObj.formula"
                  name="formula"
                  required
                  [disabled]="can_edit"
                />
              </div>
            </div>
          </div>

          <div *ngIf="optionalFields.presentValue" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.presentValue">
              <label for="presentValue" class="sidebar_label"
                >{{ trans.trans.initialCost.value }} ($)
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.initialCost"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="presentValue"
                name="presentValue"
                [rangeInput]="[0]"
                [(rangeInputModel)]="costFormObj.present_value"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [attr.ret-data]="trans.trans.initialCost.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.annualRate" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.rate">
              <label for="annualRate" class="sidebar_label"
                >{{ trans.trans.annualRate.value }} (%)
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.annualRate"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="annualRate"
                name="rate"
                [rangeInput]="[0]"
                [rangeInclusive]="false"
                [(rangeInputModel)]="costFormObj.rate"
                [lowerValidationMessage]="trans.trans.minValueExclude.value"
                [attr.ret-data]="trans.trans.annualRate.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.leaseTerm" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.leaseTerm">
              <label for="leaseTerm" class="sidebar_label"
                >{{ trans.trans.leaseTermMonths.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.leaseTermMonths"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="leaseTerm"
                name="leaseTerm"
                [rangeInput]="[1, +term * 12]"
                [(rangeInputModel)]="costFormObj.lease_term"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [upperValidationMessage]="trans.trans.maxValue.value"
                [attr.ret-data]="trans.trans.leaseTermMonths.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.residualValue" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.residualValue">
              <label for="residualValue" class="sidebar_label"
                >{{ trans.trans.residualValue.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.residualValue"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="residualValue"
                name="residualValue"
                [rangeInput]="[0, 100]"
                [(rangeInputModel)]="costFormObj.residual_value"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [upperValidationMessage]="trans.trans.maxValue.value"
                [attr.ret-data]="trans.trans.residualValue.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>

          <div *ngIf="optionalFields.buyoutValue" class="row">
            <div class="col-xs-12" appNGError [elemControl]="formAddEditCost.controls.buyoutValue">
              <label for="buyoutValue" class="sidebar_label"
                >{{ trans.trans.buyoutResidualValue.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.buyoutResidualValue"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="buyoutValue"
                name="buyoutValue"
                [rangeInput]="[0]"
                [(rangeInputModel)]="costFormObj.buyout_value"
                [lowerValidationMessage]="trans.trans.minValue.value"
                [attr.ret-data]="trans.trans.buyoutResidualValue.value"
                required
                [disabled]="can_edit"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label"
              >{{ trans.trans.categoryCost.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.categoryCost"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>

            <div class="input-group keywest_search">
              <div class="input-group-addon btn_customizable left-select-addon">
                <i
                  [matTooltip]="trans.trans.AddCostCategory.value"
                  matTooltipPosition="above"
                  (click)="openAddCostCategory = !openAddCostCategory"
                  class="i-white"
                  [ngClass]="openAddCostCategory ? 'clickable fa fa-minus' : 'clickable fa fa-plus'"
                ></i>
              </div>
              <select
                class="form-control"
                [(ngModel)]="costFormObj.cost_category_type_id"
                name="cost_category_type_id"
                [attr.ret-data]="trans.trans.AddCostCategory.value"
                [disabled]="can_edit"
              >
                <option disabled="disabled">{{ trans.trans.chooseOne.value }}</option>
                <option *ngFor="let costCategoryType of costCategoryTypes" [value]="costCategoryType.id">
                  {{ costCategoryType.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="keywest_subpanel" style="margin-bottom: 30px" *ngIf="openAddCostCategory">
          <div class="row">
            <div class="col-xs-12">
              <div class="sidebar_header">
                {{ trans.trans.AddCostCategory.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.AddCostCategory"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <i (click)="closeNewCostCategory()" class="fa fa-times clickable pull-right"></i>
              </div>
            </div>
          </div>

          <form name="addNewCostCategoryForm" #addNewCostCategoryForm="ngForm">
            <div class="row" style="margin-bottom: 30px">
              <div class="col-xs-12" appNGError [elemControl]="addNewCostCategoryForm.controls.newCostCategory">
                <label class="sidebar_label"
                  >{{ trans.trans.categoryName.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.categoryName"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <input type="hidden" name="newCostCategory" [(ngModel)]="newCostCategory" [attr.ret-data]="trans.trans.categoryName.value" required />
                <p-dropdown
                  appendTo="body"
                  class="keywest_dropdown"
                  [options]="factCostCategoryTypes"
                  name="newCostCategory"
                  [(ngModel)]="newCostCategory"
                  [filter]="true"
                  filterBy="label"
                  [disabled]="can_edit"
                ></p-dropdown>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <app-btn-save
                  *ngIf="openAddCostCategory"
                  [form]="addNewCostCategoryForm"
                  (callback)="addNewCostCategory(addNewCostCategoryForm)"
                  text="{{ trans.trans.add.value }}"
                  class="btn btn_customizable pull-right"
                ></app-btn-save>
              </div>
            </div>
          </form>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label for="expense_type_id" class="sidebar_label"
              >{{ trans.trans.expenseType.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.expenseType"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <select
              class="form-control role-dropdown"
              [(ngModel)]="costFormObj.expense_type_id"
              [attr.ret-data]="trans.trans.expenseType.value"
              id="expense_type_id"
              name="expense_type_id"
              [disabled]="can_edit"
            >
              <option disabled="disabled">{{ trans.trans.chooseOne.value }}</option>
              <option *ngFor="let expenseType of expenseTypes" [value]="expenseType.id">
                {{ expenseType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row" *ngIf="situationsTied && situationsTied.length > 0">
          <div class="col-xs-12">
            <label class="sidebar_label"
              >{{ trans.trans.situations.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.situations"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <ul>
              <li *ngFor="let situation of situationsTied">
                {{ situation.name }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label"
              >{{ trans.trans.includedInValueProp.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.includedInValueProp"
                (callback)="getTranslations()"
              ></app-editTranslation> </label
            ><br />
            <p-inputSwitch name="costInValueprop" [disabled]="can_edit" [(ngModel)]="costFormObj.costInValueprop"></p-inputSwitch>
            <div class="switch_inline">{{ costFormObj.costInValueprop ? trans.trans.yes.value : trans.trans.no.value }}</div>
          </div>
          <div class="col-xs-12">
            <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
            <br />
            <p-inputSwitch [(ngModel)]="costFormObj.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
            <div class="switch_inline">{{ costFormObj.has_tag ? trans.trans.yes.value : trans.trans.no.value }}</div>
          </div>
        </div>
        
        <!-- Cost Value Tag -->
        <div
          *ngIf="costFormObj.has_tag"
          class="col-md-4"
          appNGError
          [elemControl]="formAddEditCost.controls.valueTag"
          [ngClass]="{ 'field-error': costFormObj.nameTag === costFormObj.valueTag }"
        >
          <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.costValueTag.value }}</label>
          <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="costFormObj.valueTag"
            name="valueTag"
            ret-data="Value Tag"
            maxlength="255"
            appSpaceToUnderscore
            appSpecialCharacterValidator
            [required]="costFormObj.has_tag"
          />
          <span *ngIf="costFormObj.has_tag" class="hint light-h"
            >[{{ trans.trans.onShow.value }}{{ costFormObj.valueTag ? costFormObj.valueTag : trans.trans.value.value }}]</span
          >
          <span class="input-error-warn" *ngIf="formAddEditCost.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
        </div>
      </div>
    </div>
  </div>

  <div class="btn_container" [ngClass]="{'xplatform-btn-container-adjustment': isUsingXPlatformCrm }">
    <div class="row">
      <div class="col-md-12">
        <input type="hidden" name="account_solution_cost_id" [(ngModel)]="costFormObj.id" />
        <app-btn-save [form]="formAddEditCost" (callback)="SaveCosts(formAddEditCost)" [text]="trans.trans.save.value" class="btn btn_customizable pull-right xf-continue-button"></app-btn-save>
        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="close()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</form>

<div class="models_panel xf_models_panel" style="margin-bottom: 20px; padding: 0px">
  <div class="models_page_header_container_small" style="margin-top: 0px">
    <div class="keywest_header no-gap-bottom">
      <div *ngIf="bI.benefitsLoader" style="margin-top: 10px">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <div class="row" style="margin-bottom: 24px">
        <div class="col-xs-4" *ngIf="!showAddBenefit">
          <div class="title">
            <app-editTranslation
              *ngIf="showEditTranslate"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.benefits_financial"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ trans.trans.benefits_panel_title.value }}
          </div>
        </div>

        <div class="col-xs-2" *ngIf="showAddBenefit">
          <div (click)="toggleAddBenefit()" class="clickable a-link-color">
            <i class="fa fa-chevron-left"></i>
            <span class="marg-h">
              <app-editTranslation
                *ngIf="showEditTranslate"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.benefits_non_financial"
                (callback)="getTranslations()"
              ></app-editTranslation>
              {{ trans.trans.back_to_benefits_list.value }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <app-benefit-actions-menu
          *ngIf="!bI.benefitsLoader && !showAddBenefit"
          [benefitOnFly]="benefitOnTheFlyPrivilege"
          [benefitsListByTypeFromFull]="benefitsListByType"
          (addBenefit)="toggleAddBenefit()"
        >
        </app-benefit-actions-menu>
      </div>
    </div>

    <div class="row" *ngIf="!showAddBenefit && !bI.benefitFirstLoad">
      <div class="col-xs-12" appHeightCache [trigger]="bI.heightCacheTrigger">
        <ng-container>
          <div
            *ngFor="let benefitType of benefitsListByType | filterBenefitTypeByStatus: bI.showActive:bI.showNA:bI.showSoft"
            id="drag-container"
            cdkDropList
            (cdkDropListDropped)="drop($event, benefitType.vcs)"
            [cdkDropListDisabled]="bI.locked || !canEdit || bI.group_selected !== 'value_category'"
            class="benefit-container"
          >
            <div style="margin-bottom: 20px" class="financial-benefit breadcrumb-title-active" *ngIf="benefitType.id === '1'">
              <app-editTranslation
                *ngIf="showEditTranslate"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.benefits_financial"
                (callback)="getTranslations()"
              ></app-editTranslation>
              {{ trans.trans.benefits_financial.value }}
            </div>
            <div style="margin-bottom: 20px" class="financial-benefit breadcrumb-title-active" *ngIf="benefitType.id !== '1'">
              <app-editTranslation
                *ngIf="showEditTranslate"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.benefits_non_financial"
                (callback)="getTranslations()"
              ></app-editTranslation>
              {{ trans.trans.benefits_non_financial.value }} : {{ benefitType.name }}
            </div>
            <div *ngFor="let benefitCategory of benefitType.vcs; let b = index" cdkDrag [cdkDragData]="benefitCategory" [cdkDragPreviewClass]="'default-dnd-theme'">
              <div class="bordered-panel-tile" cdkDragHandle *ngIf="benefitCategory.metrics | functionPipe: showGroup:bI.forceGroupRefresh">
                <div class="row">
                  <div class="col-xs-12 clickable">
                    <table style="table-layout: fixed; width: 100%">
                      <tr>
                        <td width="20px" class="benefit-text expand-arrow" (click)="bI.expandCat(benefitCategory)" [attr.data-heap]="'value-execution-add-a-new-value-proposition-results-benefits-section-arrow-' + benefitCategory.name.toLowerCase().split(' ').join('-')" [ngClass]="'value-execution-add-a-new-value-proposition-results-benefits-section-arrow-' + b">
                          <span class="material-icons-outlined accordion">
                            {{ benefitCategory.expand ? 'arrow_drop_down' : 'arrow_right' }}
                          </span>
                        </td>
                        <td class="w-25" colspan="2">
                          <span class="clickable benefit-text" (click)="bI.expandCat(benefitCategory)" [attr.data-heap]="'value-execution-add-a-new-value-proposition-results-benefits-section-' + benefitCategory.name.toLowerCase().split(' ').join('-')" [ngClass]="'value-execution-add-a-new-value-proposition-results-benefits-section-' + b">
                            {{ benefitCategory.name }}
                          </span>

                          <span
                            *ngIf="benefitCategory.description"
                            class="material-icons-outlined tooltip-custom info-icon"
                            [matTooltip]="benefitCategory.description"
                            matTooltipPosition="above"
                            container="body"
                          >
                            info
                          </span>

                          <i 
                            (click)="editValueCategory(benefitCategory)" 
                            *ngIf="!bI.locked && canEdit && benefitCategory.id !== '0' && !disableEdits" 
                            class="fa fa-pencil clickable" 
                            style="margin-left: 10px"
                          ></i>
                          <div *ngIf="benefitCategory.edit_value_category" class="input-group" style="width: 100%" [matTooltip]="trans.trans.ch_renameValueCategory.value" matTooltipPosition="above">
                            <input type="text" name="value_category" [(ngModel)]="benefitCategory.alt_name" class="form-control" />
                            <div class="input-group-btn">
                              <span
                                (click)="closeValueCategoryEdit(benefitCategory)"
                                class="clickable input-group-addon action-button"
                              >
                                <i class="fa fa-times"></i>
                              </span>
                              <span
                                (click)="updateValueCategoryName(benefitCategory)"
                                class="clickable input-group-addon action-button"
                              >
                                <i class="fa fa-save"></i>
                              </span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="badge-wrapper">
                              <span class="full-badge full-badge-success">{{ benefitCategory.active_count }} {{ trans.trans.active.value }}</span>
                            </div>
                            <div class="badge-wrapper">
                              <span class="full-badge marg-h full-badge-danger">{{ benefitCategory.na_count }} {{ trans.trans.na.value }}</span>
                            </div>
                            <div class="badge-wrapper">
                              <ng-container *ngIf="!bI.feature39 && !bI.feature46">
                                <span class="full-badge full-badge-warning">{{ benefitCategory.soft_count }} {{ trans.trans.soft.value }}</span>
                              </ng-container>
                            </div>
                          </div>
                        </td>

                        <td width="20px">
                          <div style="float: left">
                            <i
                              class="fa fa-chevron-circle-left clickable"
                              (click)="benefitCategory.start = bI.rangeLeftArrow(benefitCategory.start, benefitCategory.term)"
                              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== 1"
                              style="color: green; font-weight: bold; float: left; margin-top: 15px"
                            ></i>
                          </div>
                        </td>
                        <td *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start:termLimit:termShow; let i = index" class="table-cell-year">
                          <div [ngClass]="bI.alignYear(i)">
                            <div class="vwad-benefit-size">{{ year.label }}</div>
                            <span class="benefit-text year">
                              {{ year.value }}
                            </span>
                          </div>
                        </td>
                        <td width="20px">
                          <div style="float: right; text-align: right">
                            <i
                              class="fa fa-chevron-circle-right clickable"
                              (click)="benefitCategory.start = bI.rangeRightArrow(benefitCategory.start, benefitCategory.term)"
                              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== +benefitCategory.term - termLimit"
                              style="color: green; font-weight: bold; margin-top: 15px"
                            ></i>
                          </div>
                        </td>
                        <td style="text-align: right" width="90px">
                          <div class="vwad-benefit-size">{{ trans.trans.total.value }}</div>
                          <span class="benefit-text total">
                            {{ benefitCategory.vc_total_fmt }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="panel-body" *ngIf="benefitCategory.expand && (benefitCategory.metrics | functionPipe: showGroup:bI.forceGroupRefresh)">
                <app-benefit-full
                  [mini]="bI.mini"
                  [locked]="bI.locked"
                  [valueProp]="bI.valueProp"
                  [index]="b"
                  [benefitCategory]="benefitCategory"
                  [termShow]="termShow"
                  [termLimit]="termLimit"
                ></app-benefit-full>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="showAddBenefit">
      <app-add-benefits-component *ngIf="!bI.modelsv2" (cancelBenefitCallback)="toggleAddBenefit()" (saveBenefitCallback)="savedBenefit()" [valueProp]="bI.valueProp">
      </app-add-benefits-component>

      <app-ae-benefits
        *ngIf="bI.modelsv2"
        [solutionId]="solutionId"
        [can_edit]="true"
        [isOnTheFlyBenefit]="true"
        [valuePropId]="bI.valueProp.id"
        (callback)="savedBenefit()"
        (cancelBenefitCallback)="toggleAddBenefit()"
      ></app-ae-benefits>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CollaborationTranslations } from './collaboration.translation';
import { CollaborationServices } from '@data/services/collaboration/collaboration.service';
import { CollaborationComponent } from './collaboration.component';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CollaborationEmailComponent } from './collaboration-email/collaboration-email.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    DLProgressbarModule,
    MatTooltipModule,
    NgbModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    EditTranslationsModule,
    SidebarModule,
    ApplicationPipesModule,
    KeyFiguresModule,
    MenubarModule,
    MenuModule,
    DropdownModule,
    CalendarModule,
    AreYouSureModule,
    ErrorInputWrapperModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    InputSwitchModule,
    InputTextareaModule,
    EditorModule
  ],
  declarations: [CollaborationComponent, CollaborationEmailComponent],
  providers: [
    CollaborationTranslations,
    TranslationsV2Service,
		CollaborationServices,
	],
  exports: [CollaborationComponent],
})
export class CollaborationModule {}

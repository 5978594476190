import { snakeCase } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { CommonService } from '@data/services/common/common.service';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { NotificationService } from '@services/notification.service';
import { Capability } from '@shared/models/capabilities.models';
import { of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ModelsV2AECapabilitiesTranslations } from './ae_capabilities.translation';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { CrmType } from '@shared/models/constants';

@Component({
  selector: 'app-ae-capabilities',
  templateUrl: './ae_capabilities.component.html',
})
export class AECapabilitiesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() mode = 'add';
  @Input() selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };
  @Output() callback = new EventEmitter();
  @Output() closeCallback = new EventEmitter();
  @ViewChild('formAddEditCapabilities') formAddEditCapabilities: NgForm;

  name = '';
  description = '';
  showAdd = false;
  capabilities: Capability[] = [];
  account_id: string;
  sidebar_title: { value?: string } = {};
  toggleAddEditCapability: boolean;
  cols: Array<{ field: string; header: string }> = [{ field: 'name', header: 'Capabilities' }];
  strSearch = '';
  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  AssetTagArray = AssetTagArray;
  isLoading = false;
  public isUsingXPlatformCrm = false;

  constructor(
    private painPoint: CapabilitiesService,
    private commonService: CommonService,
    public trans: ModelsV2AECapabilitiesTranslations,
    private translationService: TranslationsV2Service,
    private notificationService: NotificationService,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit(): void {
    this.isUsingXPlatformCrm = this.commonService.getCrmType() === CrmType.XPLATFORM;
    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_capability : this.trans.trans.edit_capability;
      });
  }

  insertCapability(form: NgForm): void {
    this.isLoading = true;

    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.painPoint
      .postCapability(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((capRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(capRes.result, this.selectedElem.name, this.selectedElem.tag, AssetTagArray.Capabilities, true);
            return this.customAssetTagService.createAssetTag(payload);
          } else {
            return of(capRes);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('addedSuccessfully');
        this.callback.emit();
      });
  }

  updateCapability(form: NgForm): void {
    this.isLoading = true;
    const payload = {
      account_capability_id: form.controls.id.value,
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.painPoint
      .putCapability(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((response) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.name,
              this.selectedElem.tag,
              AssetTagArray.Capabilities,
              true,
              assetId
            );
            return this.customAssetTagService.editAssetTag(payload);
          } else if (this.selectedElem.tag_data) {
            return this.customAssetTagService.deleteAssetTag(this.selectedElem.tag_data?.id);
          } else {
            return of(response);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('updatedSuccessfully');
        this.callback.emit();
      });
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        tag: snakeCase(this.selectedElem.name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notify('customCapabilityTagWarning');
    }
  }

  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}

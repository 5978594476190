import { Component, Input, OnInit } from '@angular/core';
import { CollaborationServices } from '@data/services/collaboration/collaboration.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CollaborationTranslations } from '../collaboration.translation';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import Quill from 'quill';
import { Collaboration } from '@shared/models/collaboration.models';

// Define a custom Quill blot to make text non-editable for email
let Block = Quill.import('blots/block');
class NonEditableBlot extends Block {
  static blotName = 'non-editable';
  static tagName = 'div';
  static className = 'non-editable';

  static create(value: any) {
    let node = super.create();
    node.setAttribute('contenteditable', 'false');
    return node;
  }
}

// Register the custom blot
Quill.register(NonEditableBlot);

@Component({
  selector: 'app-collaboration-email',
  templateUrl: './collaboration-email.component.html',
  styleUrls: ['./collaboration-email.component.scss']
})
export class CollaborationEmailComponent implements OnInit {
  public email: string = "Test Email";
  @Input() collaboration: Collaboration;
  linkPlaceholder: string = '[Link to proposal]';
  public readonly test = 'blah blah'
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(    
    public trans: CollaborationTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.email = this.trans.trans.defaultColabEmailBody.value;
        const emailParts = this.email.split('\n\n');
        this.email = `<p>${emailParts[0]} ${this.collaboration.user_name.trim()},</p>
        </br>
        <p>${emailParts[1]}</p>
        </br>
        <a href="${this.collaboration.link.trim()}">${emailParts[2]}</a>
        </br>
        </br>
        <div class="non-editable">${emailParts[3]} ${this.collaboration.password.trim()}</div>
        </br>
        <p>${emailParts[4]}</p>
        </br>
        <p>${emailParts[5]}</p>`
      });
  }

  public copyToClipboard(emailCustomer = false): void {
    let editorContent;
    if(emailCustomer) {
      let text = this.email;
      // Doing this because mailto only deals with plain text So need to show link for now.
      text = text.replace('[Link to proposal]', `Link to proposal: ${this.collaboration.link}`)
      editorContent = text
    } else {
      editorContent = this.email;
    }
    // Create a temporary element to hold the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = editorContent;
    // Remove any background color styles from the content
    const elements = tempDiv.querySelectorAll('*');
    elements.forEach((el: any ) => {
      el.style.backgroundColor = 'transparent'; // Remove background color
    });
    // Add the div to the DOM temporarily
    document.body.appendChild(tempDiv);
    // Select the content and copy it
    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    // Copy the selected content to the clipboard
    try {
      document.execCommand('copy');
      if(!emailCustomer)
      this.notificationService.success(this.trans.trans.copiedToClipboard.value, false);
    } catch (err) {
      if(!emailCustomer)
      this.notificationService.error(this.trans.trans.failedToCopyToClipboard.value, false);
    }
    // Clean up: remove the temporary div and clear the selection
    selection.removeAllRanges();
    document.body.removeChild(tempDiv);
  }

  public sendEmail() {
    this.copyToClipboard(true);
    navigator.clipboard.readText()
      .then(text => {
        const email = this.collaboration.email;
        const subject = 'Subject';
        const body = text;

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        
        window.location.href = mailtoLink;
      });
  }
}

import { snakeCase } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { NotificationService } from '@services/notification.service';
import { of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ModelV2AEOPObjectiveTranslations } from './ae_op_objectives.translation';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { CrmType } from '@shared/models/constants';

@Component({
  selector: 'app-ae-op-objectives',
  templateUrl: './ae_op_objectives.component.html',
})
export class AEOpObjectivesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() mode = 'add';
  @Output() callback = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  account_id: string;

  @ViewChild('formAddEditOpObjective') formAddEditOpObjective: NgForm;
  sidebar_title = 'Add Operational Objective';
  ngUnsubscribe$ = new Subject();

  @Input() selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };
  showTranslate = false;
  isLoading = false;
  public isUsingXPlatformCrm = false;

  constructor(
    private painPointService: OperationalObjectivesService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    public trans: ModelV2AEOPObjectiveTranslations,
    private translationService: TranslationsV2Service,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit(): void {
    this.isUsingXPlatformCrm = this.commonService.getCrmType() === CrmType.XPLATFORM;
    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_op_ob.value : this.trans.trans.edit_op_ob.value;
      });
  }

  insertOpObjective(form: NgForm): void {
    this.isLoading = true;

    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.painPointService
      .postOperationalObjective(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.ngUnsubscribe$),
        switchMap((capRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(capRes.result, this.selectedElem.name, this.selectedElem.tag, AssetTagArray.OperationalObjectives, true);
            return this.customAssetTagService.createAssetTag(payload);
          } else {
            return of(capRes);
          }
        })
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('successCreate');
        this.callback.emit();
      });
  }

  updateOpObjective(form: NgForm): void {
    this.isLoading = true;

    const payload = {
      account_operational_objective_id: form.controls.id.value,
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.painPointService
      .putOperationalObjective(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((response) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.name,
              this.selectedElem.tag,
              AssetTagArray.OperationalObjectives,
              true,
              assetId
            );
            return this.customAssetTagService.editAssetTag(payload);
          } else {
            return of(response);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('successUpdate');
        this.callback.emit();
      });
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        tag: snakeCase(this.selectedElem.name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notify('ooTagWarning');
    }
  }

  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}

import { Component, OnInit, ViewChild, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { DefaultTranslations } from './ae_list.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import {AccountService} from '@data/services/account/account.service';
import {Term} from '@data/services/account/models/term.interface';
import {ModelCategory} from '@data/services/account/models/model-category.interface';
import { ColumnMeta } from '@shared/models/common.models';
import { Model } from '@data/services/account/models/model.interface';
import { CrmType } from '@shared/models/constants';

@Component({
	selector: 'app-ae-solution-list',
	templateUrl: './ae_list.component.html'
})
export class AESolutionListComponent implements OnInit, OnDestroy {
	@Input() can_edit = false;
	@Input() mode = 'add';
	@Input() filterList = '';
	@Output() callback = new EventEmitter();
	@Output() closeCallback = new EventEmitter();

	image_url: string;
	fullImagePath: string;
	sidebar_title: {value: string} = {value: ''};
	ngUnsubscribe = new Subject();

	cols: ColumnMeta[] = [
		{ field: 'name', header: 'Model Name' },
		{ field: 'releasedToUsers', header: 'Released to Users' },
		{ field: 'releasedToPartners', header: 'Released to Partners' }
	];

	@Input() frmSolutionObj: Partial<Model> = {
		name: '',
		description: '',
		avg_deployment_time: '',
		typical_deployment_type: '',
		quantity_enabled: '',
		avg_sale_price: '',
		avg_sale_cycle: '',
		id: '',
		category_id: '',
		solution_category_id: [],
		term: null,
	};

	categoryList: ModelCategory[];
	selectedCategories: ModelCategory[];
	termList: Term[];
	selectedTerm: Term;

	strSearch = '';

	account_id: string;
	showTranslate = false;
	public isUsingXPlatformCrm = false;

	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private notification: NotificationService,
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service,
		private accountService: AccountService,
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit(): void {
		this.isUsingXPlatformCrm = this.commonService.getCrmType() === CrmType.XPLATFORM;
		this.getTranslations();
		this.account_id = sessionStorage.getItem('aid');

		this.getAccountTerms();
		this.getSolutionCategories();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.closeCallback.emit();
	}

	getSolutionCategories(): void {
		this.solutionService.getSolutionCategoryList().subscribe(res => {
			this.categoryList = res.result;
			if (this.mode === 'edit') {
				this.selectedCategories = this.funcFindObject('id', this.frmSolutionObj.category_id || this.frmSolutionObj.solution_category_id);
			} else if (this.mode === 'add') {
				this.selectedCategories = [];
			}
		});
	}

	getAccountTerms(): void {
		this.accountService.getTerms().subscribe(terms => {
			this.termList = terms;
			if (this.mode === 'edit') {
				const existingTerm = this.termList.find(term => term.term === this.frmSolutionObj.term);
				if (!existingTerm) {
					this.termList = [{term: null, name: '', is_default: false}, ...this.termList];
				} else {
					this.selectedTerm = existingTerm;
				}
			} else if (this.mode === 'add') {
				const defaultTerm = this.termList.find(term => term.is_default);
				this.selectedTerm = defaultTerm;
			}
		})
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
			this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_model : this.trans.trans.edit_model;
		});
	}

	funcFindObject(left, right) {
		const out = [];
		const list = typeof right === 'string' ? right.split(',') : right;
		if (list && list.length) {
			list.forEach(item => {
				const existingCategory = this.categoryList.find(x => x[left] == item);

				if (existingCategory) {
					out.push(existingCategory);
				}
			});
		}
		return out;
	}

	insertSolution(form: NgForm): void {
		const categories = form.controls.solution_category_id.value;
		const selectedCategories = categories.map(c => c.id);

		const payload = {
			name: form.controls.name.value,
			description: form.controls.description.value,
			quantity_enabled: 0,
			avg_deployment_time: form.controls.avg_deployment_time.value,
			typical_deployment_type: 0,
			solution_category_id: selectedCategories,
			term: form.controls.term.value.term,
		};

		this.solutionService.createSolutionModel(payload).subscribe(() => {
			this.notification.success('Solution created successfully', false);
			this.callback.emit();
		});
	}

	updateSolution(form: NgForm) {
		const categories = form.controls.solution_category_id.value;
		const selectedCategories = categories.map(c => c.id);

		const payload = {
			id: form.controls.id.value,
			name: form.controls.name.value,
			description: form.controls.description.value,
			avg_deployment_time: form.controls.avg_deployment_time.value,
			typical_deployment_type: form.controls.typical_deployment_type.value,
			quantity_enabled: form.controls.quantity_enabled.value,
			solution_category_id: selectedCategories,
			term: form.controls.term.value.term,
		};

		this.solutionService.updateSolutionDetail(form.controls.id.value, payload).subscribe((response) => {
			if (response.result) {
				this.notification.success('Solution updated successfully', false);
				this.callback.emit();
			}
		});
	}
}

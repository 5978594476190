import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { Currency, NumberFormat } from '@data/services/valueprop/models/situation-opportunity.model';
import { LanguageType } from '@shared/models/user-info.model';
import { TeamMember } from '@shared/models/team-member';
import { UserPrivilege } from '@shared/models/user-info.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject, zip } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserProfileTranslation } from './user-profile.translation';
import { AppImplementation } from 'app/app/app.implementation';

export interface CurrentUser {
  id?: string;
  email: string;
  managerEmail: string
  firstName: string;
  lastName: string;
  phone: string;
  privileges: UserPrivilege[];
  numberFormat: string;
  currencyType: string;
  currencyTypeId: string;
  language: string;
  languageTypeId: string;
  role: string;
  profilePicFilepath: string;
  manager?: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  public breadcrumbKeyValues: Breadcrumbs;
  public style2022$: Observable<boolean>;
  public userId: number;
  public user: CurrentUser;
  public numberFormats: NumberFormat[] = [];
  public languageTypes: LanguageType[] = [];
  public currencyTypes: Currency[] = [];
  public teamMembers: TeamMember[] = [];

  private unsubscribe = new Subject<void>();

  constructor(
    public trans: UserProfileTranslation,
    public aI: AppImplementation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private userService: UserService,
    private factsService: FactsService,
    private styleService: StyleService
  ) { }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.aI.checkForCrms();
    this.userId = parseInt(sessionStorage.getItem('uid'));
    this.getUserData();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  private getUserData() {
    zip(...this.getDataCalls())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.user = this.parseUserData(data[0].result);
        this.languageTypes = data[1].result;
        this.currencyTypes = data[2].result;
        this.numberFormats = data[3].result;
        this.teamMembers = data[4].result.team_members;
      })
  }

  private getDataCalls() {
    return [
      this.userService.getUser(this.userId),
      this.userService.getLanguages(),
      this.userService.getCurriences(),
      this.factsService.getNumberFormats(),
      this.userService.getteamUsersList()
    ]
  }

  private parseUserData(user): CurrentUser {
    const {
      id, email, manager_email, first_name, last_name, phone, privileges, number_format,
      currency, language, role_type, profile_pic_filepath, currency_type_id, language_type_id, manager_name
    } = user
    return {
      id,
      email,
      phone,
      privileges,
      language,
      role: role_type,
      firstName: first_name,
      lastName: last_name,
      manager: manager_name,
      currencyType: currency,
      managerEmail: manager_email,
      numberFormat: number_format,
      currencyTypeId: currency_type_id,
      languageTypeId: language_type_id,
      profilePicFilepath: profile_pic_filepath
    }
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      this.breadcrumbKeyValues = {
        [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
        [this.trans.trans.profile.value]: { routerLink: '/full-profile', active: true },
      };
      this.userService.getLanguages(false, true)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.languageTypes = data.result;
        })
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CostTranslations } from './cost.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import {ModelCost} from '@data/services/solution/models/model-cost';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-costs',
	templateUrl: './cost.component.html'
})
export class CostComponent implements OnInit, OnDestroy {
	@Input() solutionID: string;
	@Input() can_edit = false;
	@Input() term = "";

	ngUnsubscribe = new Subject();

	costs: ModelCost[] = [];
	costCategoryTypes: any = [];
	expenseTypes: any[];
	accrualTypes: any[];
	scaleTypes: any[];
	modalReference: any;
	image_url: string;
	fullImagePath: string;
	accountSolutionId: number;

	account_id: any;

	// Contextual Help
	contextualHelp: object = {};
	costId: any;
	hideActionLoader: boolean = true;
	mode: any = "Add";
	dropdownSettings: any = {};
	drivers: any = [];

	@ViewChild('formAddEditCost') formAddEditCost: NgForm;

	@ViewChild('addNewCostCategoryForm') addNewCostCategoryForm: NgForm;

	costFormObj: Partial<ModelCost>;

	strSearch = '';

	// new
	addEditCost: any;
	loadingSave: boolean = false;
	toggleAddCost: boolean = false;
	dropdown: any[] = [];
	sidebar_title: string = "Add New Cost";
	openAddCostCategory: boolean = false;
	showTranslate = false;

	cols = [
		{ field: 'name', header: 'Line Item' },
		{ field: 'cost', header: 'Unit Price' }
	];

	loaded: boolean = false;
	style2022$: Observable<boolean>;
	precisionOf5 = this.commonService.checkFeature(157);

	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private notification: NotificationService,
		private translationService: TranslationsV2Service,
		public trans: CostTranslations,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.solutionService.triggerCost.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getSolutionCostList();
		});

		this.getSolutionCostList();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);

		const payload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans
		}
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}


	funcToggleView(elem) {
		this.toggleAddCost = true;
		this.costFormObj = Object.assign({}, elem);
	}

	funcShowAddCost() {
		this.toggleAddCost = !this.toggleAddCost;
		this.sidebar_title = "Add New Cost";
		this.mode = "add";
		this.costFormObj = {};
	}

	getSolutionCostList() {
		this.loaded = false;
		this.solutionService.getSolutionCosts(this.solutionID).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.costs = response.result.map(c => {
					c.displayUnitPrice = ['1', '2'].includes(c.accrual_type_id);
					return c;
				}).sort((a, b) => a.sequence - b.sequence);

				this.costs.forEach(element => {
					if ( element.has_tag ) {
					  element.valueTag = element.tag;
					  element.nameTag = element.tag_data.name;
					} else {
					  element.valueTag = '';
					  element.nameTag = '';
					}
				  });
			}
			else {
				this.costs = [];
			}
			this.loaded = true;
		});
	}

	editSolutionCosts(elem) {
		this.toggleAddCost = true;
		this.mode = "edit";
		this.costFormObj = Object.assign({}, elem);
	}

	deleteSolutionCost(id) {
		this.hideActionLoader = false;
		this.solutionService.deleteSolutionCosts(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getSolutionCostList();
			this.hideActionLoader = true;
			this.notification.success("Solution Cost deleted successfully", false);// Params {message, islogout}
		});
	}
}

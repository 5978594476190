<div *ngIf="showFullProfile">
    <!-- Update Password dialog -->
    <p-dialog id="changePassword" [modal]="true" [style]="{width: '20vw'}" (onHide)="resetPassForm()" [header]="trans.trans.changePassword.value"
        [(visible)]="change_pwd">
        <app-change-password #passwordForm (change_pwd)="closePopup($event)"></app-change-password>
    </p-dialog>
    <!-- Edit Profile Sidebar -->
    <p-sidebar class="keywest_sidebar" [(visible)]="edit_info" position="right" [style]="{width: '385px'}" (onHide)="resetUserForm()" >
        <app-edit-profile-info #editUserForm (update)="onEditUserSidebarSave($event)" (closeFlyout)="closeFlyout($event)" [user]="user">
        </app-edit-profile-info>
    </p-sidebar>
    <!-- Edit Password Dialog Trigger -->
    <div class="row password-link-wrapper" >
        <a class="mxy-10" (click)="openPopup()">{{trans.trans.changePassword.value}}</a>
    </div>
    <!-- Picture -->
    <div class="row" style="height: 142px;">
        <span class="img-wrapper">
            <img class="img-responsive img-circle profile-img"
                [src]="user.profilePicFilepath != '' ? user.profilePicFilepath : profilepicdefaultpath">
            <!-- Upload Picture Trigger -->
            <span (click)="uploadInput.click()" class="img-upload material-icons keywest_header_icon clickable new-edit-profile-icon">
                edit
            </span>
            <!-- Picture File Input -->
            <input type="file" class="form-control file-input" name="profile_image" accept="image/*"
                (change)="handleInputChange($event)" #uploadInput />
        </span>
    </div>
    <!-- Name / Role-->
    <div class="row user-info-wrapper center-text ">
        <h1 style="font-size: 20px;" class="dark-t">{{user.firstName}} {{user.lastName}}</h1>
        <p class="light-h">{{user.role}}</p>
    </div>
    <!-- Information -->
    <div class="row center-text mt-20">
        <!-- Email / Phone -->
        <p class="user-info-wrapper">
            <span *ngIf="user.email && user.email !== ''" class="light-h">{{trans.trans.email.value}}: </span>
            <span class="dark-t">{{user.email}} </span>
            <span *ngIf="user.phone && user.phone !== ''" class="light-h">
                <span class="divider"></span>
                <span> {{trans.trans.phone.value}}:</span>
                <span class="dark-t">{{user.phone}}</span>
            </span>

        </p>
    </div>

    <div class="row center-text ">
        <!-- Manager -->
        <p class="user-info-wrapper">
            <span *ngIf="user.manager && user.manager !== ''" class="light-h">{{trans.trans.manager.value}}: </span>
            <span class="dark-t">{{user.manager}}</span>
        </p>
        <!-- Edit Profile Sidebar trigger -->
        <a (click)="openEditFlyOut()">{{trans.trans.editProfileInformation.value}}</a>
    </div>
</div>

<div class="row mt-20" >
    <div class="col-xs-12">

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 mx-10">
            <!-- Lang Type -->
            <p class="light-h">{{trans.trans.systemLanguage.value}}</p>
            <span class="flag-icon flag-icon-fr">
            </span>
            <select name="langType" type="text" class="form-control profile-dropdown" [(ngModel)]="user.languageTypeId"
                required (change)="setLanguage()">
                <option  [ngValue]="languageType.id" *ngFor="let languageType of languageTypes">
                    <span >{{getFlag(languageType.id)}}</span>
                    <span class="profile-drop-text">{{ languageType.name }}</span>
                </option>
            </select>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 mx-10">
            <!-- Currency Type -->
            <p class="light-h">{{trans.trans.currenyType.value}}</p>
            <select name="currencyType" type="text" class="form-control profile-dropdown"
                [(ngModel)]="user.currencyTypeId" (change)="updateUser()" required>
                <option [ngValue]="currencyType.id" *ngFor="let currencyType of currencyTypes">
                    <span class="profile-drop-text">{{ currencyType.name }}</span>
                </option>
            </select>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 mx-10">
            <!-- Number format -->
            <p class="light-h">{{trans.trans.numberFormat.value}}</p>
            <select name="numberFormat" type="text" class="form-control profile-dropdown"
                [(ngModel)]="user.numberFormat" (change)="updateUser()" required>
                <option [ngValue]="numberFormat.id" *ngFor="let numberFormat of numberFormats">
                    <span class="profile-drop-text">{{ numberFormat.name }}</span>
                </option>
            </select>
        </div>
    </div>
</div>
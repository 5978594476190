import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Subject, of } from 'rxjs';
import { filter, takeUntil, take, map, switchMap } from 'rxjs/operators';
import { FactorsService } from '@data/services/factors/factors.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AECostTranslations } from './ae_cost.translation';
import { ModelCost, ModelCostSituation } from '@data/services/solution/models/model-cost';
import { BasicInfo } from '@shared/models/basic-info.model';
import { CreateCostTagData, TagData } from '@shared/models/asset-tag.models';
import { snakeCase } from 'lodash';
import { CrmType } from '@shared/models/constants';

@Component({
  selector: 'app-ae-costs',
  templateUrl: './ae_cost.component.html',
  styleUrls: ['ae_cost.component.scss'],
})
export class AECostComponent implements OnInit, OnDestroy {
  @Input() solutionID: number | string;
  @Input() can_edit: boolean = false;
  @Input() term: string = '';
  @Output() callback = new EventEmitter();
  ngUnsubscribe = new Subject();

  loadSolutionCosts: boolean = false;
  costs: any = [];
  costCategoryTypes: any = [];
  factCostCategoryTypes: any = [];
  expenseTypes: any[];
  accrualTypes: BasicInfo[];
  scaleTypes: any[];
  modalReference: any;
  image_url: string;
  fullImagePath: string;
  accountSolutionId: number;

  situationsTied: ModelCostSituation[];
  account_id: any;

  revenueToUs: boolean;

  // Contextual Help
  contextualHelp: object = {};
  costId: any;
  hideActionLoader: boolean = true;
  @Input() mode: any = 'add';
  dropdownSettings: any = {};
  newCostCategory: any;
  drivers: any = [];
  showTranslate = false;
  termIndexArray = [];

  @ViewChild('formAddEditCost') formAddEditCost: NgForm;

  @ViewChild('addNewCostCategoryForm') addNewCostCategoryForm: NgForm;

  @Input() costFormObj: Partial<ModelCost>;

  strSearch = '';

  // new
  addEditCost: any;
  loadingSave: boolean = false;
  toggleAddCost: boolean = false;
  dropdown: any[] = [];
  sidebar_title: any = {};
  openAddCostCategory: boolean = false;
  cols = [
    { field: 'name', header: 'Line Item' },
    { field: 'cost', header: 'Unit Price' },
  ];
  dropdownValues: any;
  scalesHow: any[] = [];

  optionalFields = {
    cost: false,
    costPerYear: false,
    scaledBy: false,
    presentValue: false,
    annualRate: false,
    growthRate: false,
    leaseTerm: false,
    residualValue: false,
    buyoutValue: false,
  };

  scaledByLabel: { value: string };
  allowNegatives = this.commonService.checkFeature(138);
  public isUsingXPlatformCrm = false;

  constructor(
    private solutionService: SolutionService,
    private factorsService: FactorsService,
    private factsService: FactsService,
    private commonService: CommonService,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: AECostTranslations
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.isUsingXPlatformCrm = this.commonService.getCrmType() === CrmType.XPLATFORM;
    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.solutionService.triggerCost.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getSolutionCostList();
    });

    this.getSolutionCostList();
    this.getAccrualTypes();
    this.getScaleTypes();
    this.getCostCategoryTypes();
    this.getFactCostCategoryTypes();
    this.getExpenseTypes();
    this.loadDrivers();
    this.getDropdownValues();
    this.getScalesHow();

    if (this.mode == 'edit') {
      this.costFormObj.costInValueprop = this.costFormObj.default_include === '1';

      if (this.costFormObj.situation.length > 0) {
        this.situationsTied = this.costFormObj.situation;
      }
    }
    this.termIndexArray = Array.from(Array(+this.term).keys()).map((term) => term + 1);
  }

  accrualChange(initialLoad = false) {
    switch (this.costFormObj.accrual_type_id) {
      case '1':
        this.optionalFields = {
          cost: true,
          costPerYear: false,
          scaledBy: true,
          annualRate: false,
          growthRate: false,
          leaseTerm: false,
          presentValue: false,
          residualValue: false,
          buyoutValue: false,
        };
        this.scaledByLabel = this.trans.trans.scaled;
        break;
      case '2':
        this.optionalFields = {
          cost: true,
          costPerYear: false,
          scaledBy: true,
          annualRate: false,
          growthRate: true,
          leaseTerm: false,
          presentValue: false,
          residualValue: false,
          buyoutValue: false,
        };
        this.scaledByLabel = this.trans.trans.scaled;
        break;
      case '3':
        if (!initialLoad && this.costFormObj.cost) {
          const cost = parseInt(this.costFormObj.cost) / this.termIndexArray.length;
          this.termIndexArray.forEach((termIndex) => {
            this.costFormObj['yr' + termIndex + '_costs'] = cost;
          });
        }
        this.optionalFields = {
          cost: false,
          costPerYear: true,
          scaledBy: false,
          annualRate: false,
          growthRate: false,
          leaseTerm: false,
          presentValue: false,
          residualValue: false,
          buyoutValue: false,
        };
        break;
      case '6':
        this.optionalFields = {
          cost: false,
          costPerYear: false,
          scaledBy: true,
          annualRate: true,
          growthRate: false,
          leaseTerm: true,
          presentValue: true,
          residualValue: false,
          buyoutValue: false,
        };
        this.scaledByLabel = this.trans.trans.initialCostScaled;
        break;
      case '7':
        this.optionalFields = {
          cost: false,
          costPerYear: false,
          scaledBy: true,
          annualRate: true,
          growthRate: false,
          leaseTerm: true,
          presentValue: true,
          residualValue: true,
          buyoutValue: true,
        };
        this.scaledByLabel = this.trans.trans.initialCostScaled;
        break;
      default:
        this.optionalFields = {
          cost: false,
          costPerYear: false,
          scaledBy: false,
          presentValue: false,
          annualRate: false,
          growthRate: false,
          leaseTerm: false,
          residualValue: false,
          buyoutValue: false,
        };
        break;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.callback.emit();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'add' ? this.trans.trans.addCost : this.trans.trans.editCost;

        this.accrualChange(true);
      });
  }

  getScalesHow() {
    this.factorsService
      .getScalesHow()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.scalesHow = response.result.scaling_options;
        if (this.mode == 'edit') {
          this.costFormObj.scaleHow = this.scalesHow.filter((x) => x.id == this.costFormObj.scales_how)[0];
        }
      });
  }

  getSolutionCostList() {
    this.loadSolutionCosts = true;
    this.solutionService
      .getSolutionCosts(this.solutionID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.costs = response.result;

          this.costs.forEach(element => {
            if ( element.has_tag ) {
              element.valueTag = element.tag;
              element.nameTag = element.tag_data.name;
            } else {
              element.valueTag = '';
              element.nameTag = '';
            }
          });

        } else {
          this.costs = [];
        }
        this.loadSolutionCosts = false;
      });
  }

  getAccrualTypes() {
    this.factsService
      .getAccrualTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.accrualTypes = result.result.filter((accrualType) => accrualType.id !== '8');
      });
  }

  getScaleTypes() {
    this.factorsService
      .getScalesHow()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.scaleTypes = result.result.scaling_options;
      });
  }

  getCostCategoryTypes() {
    this.account_id = sessionStorage.getItem('aid');
    this.costCategoryTypes = [];
    this.factsService
      .getAccountCostCategoryTypes(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.costCategoryTypes = result.result;
        }
      });
  }

  getExpenseTypes() {
    this.factsService
      .getExpenseTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.expenseTypes = result.result;
      });
  }

  SaveCosts(form: NgForm) {
    this.loadingSave = true;
    if (form.controls.scales_how && form.controls.scales_how.value && form.controls.scales_how.value.id == 3 && !form.controls.formula.value) {
      this.notification.error('Formula Field is Required', false);
      return false;
    }
    const payload: Partial<ModelCost> = {
      account_solution_id: this.solutionID.toString(),
      name: form.controls.name.value,
      accrual_type_id: form.controls.accrual_type_id.value,
      description: form.controls.description ? form.controls.description.value : '',
      cost_category_type_id: form.controls.cost_category_type_id.value ? form.controls.cost_category_type_id.value : 0,
      expense_type_id: form.controls.expense_type_id.value ? form.controls.expense_type_id.value : 0,
      default_include: form.controls.costInValueprop.value ? '1' : '0',
      cost: this.optionalFields.cost && this.costFormObj.cost ? this.costFormObj.cost : '1',
      scale_type_id: this.optionalFields.scaledBy && form.controls.scaleFactor && form.controls.scaleFactor.value ? form.controls.scaleFactor.value.id : '2',
    };

    if (this.optionalFields.scaledBy) {
      payload.scales_how = form.controls.scaleHow && form.controls.scaleHow.value ? form.controls.scaleHow.value.id : 0;
      payload.ratio = form.controls.ratio ? form.controls.ratio.value : 0;
      payload.formula = form.controls.formula ? form.controls.formula.value : 0;
    }

    if (this.optionalFields.costPerYear) {
      payload.yr1_costs = form.controls.yr1_costs ? form.controls.yr1_costs.value : 0;
      payload.yr2_costs = form.controls.yr2_costs ? form.controls.yr2_costs.value : 0;
      payload.yr3_costs = form.controls.yr3_costs ? form.controls.yr3_costs.value : 0;
      payload.yr4_costs = form.controls.yr4_costs ? form.controls.yr4_costs.value : 0;
      payload.yr5_costs = form.controls.yr5_costs ? form.controls.yr5_costs.value : 0;
      payload.yr6_costs = form.controls.yr6_costs ? form.controls.yr6_costs.value : 0;
      payload.yr7_costs = form.controls.yr7_costs ? form.controls.yr7_costs.value : 0;
      payload.yr8_costs = form.controls.yr8_costs ? form.controls.yr8_costs.value : 0;
      payload.yr9_costs = form.controls.yr9_costs ? form.controls.yr9_costs.value : 0;
      payload.yr10_costs = form.controls.yr10_costs ? form.controls.yr10_costs.value : 0;
    }

    if (this.optionalFields.presentValue) {
      payload.present_value = this.costFormObj.present_value || '0';
    }

    if (this.optionalFields.annualRate || this.optionalFields.growthRate) {
      payload.rate = this.costFormObj.rate || '0';
    }

    if (this.optionalFields.leaseTerm) {
      payload.lease_term = this.costFormObj.lease_term || '0';
    }

    if (this.optionalFields.residualValue) {
      payload.residual_value = this.costFormObj.residual_value || '0';
    }

    if (this.optionalFields.buyoutValue) {
      payload.buyout_value = this.costFormObj.buyout_value || '0';
    }

    if (this.mode === 'add') {
      this.solutionService
        .createSolutionCosts(payload)
        .pipe(
          switchMap((tagResponse) => {
            if (this.costFormObj.has_tag) {
              const payload = this.createCostTagPayload(tagResponse.result.id);
              return this.solutionService.saveCostTags(payload, true).pipe(map(() => tagResponse));
            } else {
              return of(tagResponse);
            }
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((r) => {
          if (r.result.success === false) {
            this.notification.error(r.result.message, false);
            this.loadingSave = false;
          } else {
            this.notification.success('Costs created successfully', false);
            this.callback.emit('');
          }
        });
    } else if (this.mode === 'edit') {
      payload['account_solution_cost_id'] = form.controls.account_solution_cost_id.value;
      this.solutionService
        .updateSolutionCosts(payload)
        .pipe(switchMap((tagResponse) => {
          if (!this.costFormObj.has_tag && this.costFormObj.tag_data?.id) {
            // No tag, delete the one that exists
            return this.factorsService.deleteAssetTag(this.costFormObj.tag_data?.id).pipe(map(() => tagResponse));
          } else if (this.costFormObj.has_tag) {
            // Create or update tag
            const payload = this.createCostTagPayload(form.controls.account_solution_cost_id.value, this.costFormObj.tag_data?.id);
            return this.solutionService.saveCostTags(payload, true).pipe(map(() => tagResponse));
          } else {
            return of(tagResponse);
          }
        }),
        takeUntil(this.ngUnsubscribe)
        )
        .subscribe((r) => {
          if (r.result.success === false) {
            this.notification.error(r.result.message, false);
            this.loadingSave = false;
          } else {
            this.notification.success('Costs updated successfully', false); // Params {message, islogout}
            this.callback.emit('');
          }
        });
    }
  }

  getFactCostCategoryTypes() {
    this.factCostCategoryTypes = [];
    this.factsService
      .getCostCategoryTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.factCostCategoryTypes = result.result.map((fact) => ({ label: fact.name, value: fact.id }));
        }
      });
  }

  addNewCostCategory(form: NgForm) {
    let params = {
      account_id: sessionStorage.getItem('aid'),
      cost_category_type_id: form.controls.newCostCategory.value,
    };

    this.solutionService
      .addNewCostCategory(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result && result.result.success) {
          this.getCostCategoryTypes();
          this.notification.success('Costs category added successfully', false);
          this.closeNewCostCategory();
        }
      });
  }

  closeNewCostCategory() {
    this.openAddCostCategory = false;
    if (this.addNewCostCategoryForm) {
      this.addNewCostCategoryForm.reset();
    }
  }

  loadDrivers() {
    this.solutionService
      .getDrivers(this.solutionID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.drivers = response.result.drivers;
        }
      });
  }

  getDropdownValues() {
    this.dropdownValues = undefined;
    let payload = {
      driver_factor_type_id: '0',
      account_solution_id: this.solutionID,
      exclude_dns: '0',
    };
    this.solutionService.getBenefitsDropdownAggregate(this.account_id, payload).subscribe((res) => {
      this.dropdownValues = res.result;
      if (this.mode == 'edit') {
        this.costFormObj.scaleFactor = this.dropdownValues.readAccountAggregatedFactors.filter((x) => x.id == this.costFormObj.scale_type_id)[0];
      }
    });
  }

  createCostTagPayload(costId: number | string, tagId?: string): CreateCostTagData {
    return {
      costId: costId.toString(),
      tagId,
      name: this.costFormObj.nameTag,
      value: this.costFormObj.valueTag,
    };
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.costFormObj = {
        ...this.costFormObj,
        nameTag: this.costFormObj.name,
        valueTag: snakeCase(this.costFormObj.name),
      };
    } else if (this.costFormObj.tag_data?.id) {
      this.notify('customCostTagWarning', 'warning');
    }
  }

  private notify(transString: string, type: 'error' | 'warning' | 'success' = 'success') {
    this.notification[type](this.trans.trans[transString].value, false);
  }
}

import { MenuItem } from 'primeng/api';
import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Menu } from 'primeng/menu';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './costs.simple.translation';
import { AccrualType, CostResponse } from '@data/services/valueprop/models/cost.model';

@Component({
  selector: 'app-costs-simple-valueprop',
  templateUrl: './costs.simple.component.html',
  styleUrls: ['./costs.simple.component.scss'],
})
export class CostsSimpleComponent implements OnInit, OnDestroy {
  @Input() dealdesk: boolean;
  @Input() isTracker = false;
  @Input() locked = false;
  @Input() shareView = 'simple';
  @Input() showTranslate = false;
  @Input() tabInit: number;
  @Input() valueProp: ValueProp;
  @Input() viewSimple = false;
  @Input() showContinue: boolean = true;

  @Output() callbackNext = new EventEmitter();
  @Output() funcCallback = new EventEmitter();
  @Output() back = new EventEmitter();

  account_id: string;
  accrualTypes:  AccrualType[] = [];
  colspan: number;
  cost: string;
  costCategories = false;
  costCategoryTypes: Array<any>;
  costModal: { valueProp?: ValueProp; edit?: string; cost?: CostResponse } = {};
  costTypes: { [klass: string]: any }[] = [];
  costs: any[] = [];
  costsLoader = true;
  edit: boolean;
  feature91 = false;
  feature97 = false;
  fullImagePath: string;
  grouped_costs: { [klass: string]: any }[] = [];
  header_color = 'blue';
  hideCostsFeature = false;
  imageUrl: string;
  isCustomerShareType = false;
  ngUnsubscribe = new Subject();
  showAddCost = false;
  start = 1;
  term = 3;
  value_prop_costs: { [klass: string]: any }[] = [];
  years: { label: string }[] = [];
  crm: string;
  style2022$: Observable<boolean>;
  precisionOf5 = this.commonService.checkFeature(157);

  constructor(
    private commonService: CommonService,
    private valuePropService: ValuepropService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.feature91 = this.commonService.checkFeature(91);
    this.feature97 = this.commonService.checkFeature(97);
    this.hideCostsFeature = this.commonService.checkFeature(105);
    this.style2022$ = this.styleService.style2022;

    this.header_color = sessionStorage.getItem('header_color');

    this.loadCosts();
    this.valuePropService.refreshCosts.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadCosts();
      this.valuePropService.refreshDashboard.next('changeCost');
    });

    if (this.valueProp['share_role_type_id'] !== '4') {
      this.isCustomerShareType = false;
    }

    this.valuePropService
      .getAccrualTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.accrualTypes = response.result;
        }
      });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  deleteValuePropCosts(id): void {
    this.valuePropService
      .deleteValuePropCosts(this.valueProp.id, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.valuePropService.refreshDashboard.next('refreshDashboard');
          this.costTypes = response.result;

          this.loadCosts();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  loadCosts(): void {
    const searchLimit = this.valueProp.id;
    this.costsLoader = true;
    this.valuePropService
      .getValuePropCosts(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.grouped_costs = response.result.grouped_costs;
          this.value_prop_costs = response.result.value_prop_costs;
          let grouped = true;
          if (this.grouped_costs && this.grouped_costs.length === 1 && this.grouped_costs[0].id === '0') {
            this.costCategories = false;
            this.costs = response.result.value_prop_costs;
            grouped = false;
          } else {
            this.costCategories = true;
            this.costs = response.result.grouped_costs;
          }
          if (this.costs) {
            if (grouped) {
              this.term = this.costs[0].costs[0].term;
              for (let m = 0; m < this.costs.length; m++) {
                this.costs.forEach((group) => {
                  group.costs.forEach((elem) => {
                    // Clear cost_fmt if cost is variable
                    if ( elem['accrual_type_id'] === '3' ) {
                      elem['cost_fmt'] = '';
                    }

                    const out = [];
                    for (let t = 1; t <= this.term; t++) {
                      out.push({ label: 'Year ' + t, value: elem['yr' + t + '_costs_fmt'] });
                    }
                    elem.years = out;
                  });
                });
              }
            } else {
              this.term = this.costs[0].term;
              this.costs.forEach((cost) => {
                // Clear cost_fmt if cost is variable
                if ( cost['accrual_type_id'] === '3' ) {
                  cost['cost_fmt'] = '';
                }

                const out = [];
                for (let t = 1; t <= this.term; t++) {
                  out.push({ label: 'Year ' + t, value: cost['yr' + t + '_costs_fmt'] });
                }
                cost.years = out;
              });
            }

            this.years = [];
            for (let t = 1; t <= this.term; t++) {
              this.years.push({ label: 'Year ' + t });
            }
          }

          this.colspan = Number(this.term) + 5;

          this.costsLoader = false;
        }
      });
  }

  funcUpdateCostTitle(newval: string, cost): void {
    cost.alt_cost_name = newval;

    this.costsLoader = true;
    this.valuePropService
      .updateCostTitle(this.valueProp.id, cost)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.costsLoader = false;
          this.loadCosts();
        }
      });
  }

  openCosts(edit, cost): void {
    this.costModal.edit = edit;
    this.costModal.cost = cost;
    this.costModal.valueProp = this.valueProp;
    this.showAddCost = !this.showAddCost;
  }

  openAddCosts(): void {
    const cost = {
      accrual_type_id: '',
      cost: '',
      cost_type_id: '',
      discount: '',
      quantity: '',
      value_prop_id: this.valueProp.id,
      yr1_costs: '',
      yr2_costs: '',
      yr3_costs: '',
      cost_category_type_id: '0',
      account_competitor_id: '',
      present_value: '',
      rate: '',
      lease_term: '',
      residual_value: '',
      buyout_value: '',
    };
    this.openCosts(false, cost);
  }

  getCostCategoryTypes(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.factsService
      .getAccountCostCategoryTypes(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.costCategoryTypes = result.result;
      });
  }

  editValuePropCosts(cost): void {
    const costObj = {
      ...cost,
      value_prop_id: this.valueProp.id,
      alt_cost_description: cost.alt_cost_description !== '' ? cost.alt_cost_description : cost.cost_description,
      alt_cost_name: cost.alt_cost_name,
      value_prop_cost_id: cost.id,
      rate: cost.rate,
    };

    this.openCosts(true, costObj);
  }

  funcCloseAddEditCosts(): void {
    this.showAddCost = false;
    this.funcCallback.emit();
  }

  rangeRightArrow(start): number {
    if (!start) {
      return 1;
    } else {
      return start + 1;
    }
  }

  rangeLeftArrow(start): number {
    if (!start) {
      return 1;
    } else {
      return start - 1;
    }
  }

  cancelRename(cost): void {
    cost.editing = !cost.editing;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';
import { APIResult } from '@shared/models/api-result.model';
import { CompetitiveTCO, FeedbackResponse, Solution, ValueProp, ValuePropBenefitMetrics, ValuePropPhasing } from '@shared/models/value-prop.model';
import { AssetTemplate } from '@shared/models/asset.models';
import { ScoreCard } from '@shared/models/score-card.model';
import { ValuePropCollateral } from '@shared/models/collateral.model';
import { ValuePropSituation } from './models/situation.model';
import { Situation } from './models/situation-opportunity.model';
import { BenefitTurboSummedResponse } from '@shared/models/benefits.models';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { Share, SharedRole } from '@shared/models/share.models';
import { BaselineType } from './models/baselineType.model';
import { AccrualType } from './models/cost.model';
import { BasicInfo } from '@shared/models/basic-info.model';
import { AssumptionDriver, AssumptionFactorGroups, AssumptionFinancial, AssumptionScratchpad, KeyAssumptionsData } from '@shared/models/assumptions.model';
import { DiscoveryQuestionNotePayload, DiscoveryQuestionNoteResponse } from '@shared/models/discovery-question.model';
import { ScracthpadOperatorType } from '@shared/models/facts.models';
import { ReadScratchpadResult } from './models/scratchpad.model';
import { MetricCaseStudy } from '@shared/models/metric-case-study.model';

@Injectable()
export class ValuepropService {
  constructor(private commonService: CommonService) {}

  public reloadSituations = new Subject<any>();
  public gotoMenuItem = new Subject<any>();
  public repSimpleDone = new Subject<any>();
  public refreshDashboard = new Subject<any>();
  public refreshCosts = new Subject<any>();
  public refreshBenefits = new Subject<any>();
  public guidedDiscoverySave = new Subject<object>();
  public loadMenuItem = new Subject<any>();
  public callbackFinishAddJustCase = new Subject<any>();
  public rebuildMenu = new Subject<any>();
  public setSelectedBenefit = new Subject<any>();
  public refreshBenefitDetail = new Subject<any>();
  public editSituations = new Subject<any>();
  public valueProp = new BehaviorSubject<ValueProp>(null);
  public refreshDiscoveryQuestions$ = new Subject<boolean>();
  public reloadScalers$ = new Subject<boolean>();

  public createMasterScaler(id, payload) {
    let endpoint = 'valueProps/masterScaler/create/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateValuePropFactorActive(id, payload) {
    let endpoint = 'valueProps/' + id + '/factorActiveStatus';
    return this.commonService.putAPIService(endpoint, payload);
  }

  // value props
  public getValueProp(payload) {
    let endpoint = 'valueProps/searchShort';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateValueProp(id, payload) {
    let endpoint = 'valueProps/name/update/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public postAddVPtoVPGroup(vp_group: string | number, payload: { members: string[] }): Observable<APIResult<SuccessFailResponse>> {
    let endpoint = 'valueProps/valuePropGroupMember/' + vp_group;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateValuePropOpportunityId(id, payload) {
    let endpoint = 'valueProps/updateValuePropOpportunityId/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteValueProp(id) {
    let endpoint = 'valueProps/deleteValueProp/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getValuePropDetail(id: string): Observable<APIResult<{ value_prop: ValueProp }>> {
    let endpoint = 'valueProps/details/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public postEditFactorNameFromBenefits(vpid, payload) {
    let endpoint = 'valueProps/factorOverride/' + vpid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getVPGroupAssets(value_prop_group_id) {
    let endpoint = 'asset/valuePropGroupTemplates/' + value_prop_group_id;
    return this.commonService.getAPIService(endpoint);
  }

  public getSearchValueprop(payload, searchLimit) {
    let endpoint = 'valueProps/search' + searchLimit;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public year1breakdown(id) {
    let endpoint = 'valueProps/year1breakdown/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public costBenefitChart(id) {
    let endpoint = 'valueProps/costBenefitChart/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getValuePropBenefits(id: string | number, singleMetric: number, valuePropMetricId: number): Observable<APIResult<ValuePropBenefitMetrics>> {
    let endpoint = 'valueProps/readMetrics/' + id + '/showAll/1/softOnly/0/fullVcMetrics/0/altNames/0/singleMetric/' + singleMetric + '/valuePropMetricId/' + valuePropMetricId;
    return this.commonService.getAPIService(endpoint);
  }
  public getValuePropAllBenefits(id: string | number, showAll: number, singleMetric: number, valuePropMetricId: string): Observable<APIResult<ValuePropBenefitMetrics>> {
    let endpoint =
      'valueProps/readMetrics/' + id + '/showAll/' + showAll + '/softOnly/0/fullVcMetrics/1/altNames/0/singleMetric/' + singleMetric + '/valuePropMetricId/' + valuePropMetricId;
    return this.commonService.getAPIService(endpoint);
  }
  public getValuePropAllBenefitsTurbo(id, showAll) {
    let endpoint = 'valueProps/turboRead/' + id + '/showAll/' + showAll;
    return this.commonService.getAPIService(endpoint);
  }

  public getValuePropAllBenefitsTurboSummed(id: number, showAll: number, orderFlag: number, filter = 'value_category'): Observable<APIResult<BenefitTurboSummedResponse>> {
    let endpoint = 'valueProps/turboRead/' + id + '/showAll/' + showAll + '/summedBenefits/1/orderFlag/' + orderFlag + '/filterBy/' + filter;
    return this.commonService.getAPIService(endpoint);
  }

  public updateBenefitDriver(id, payload) {
    let endpoint = 'valueProps/updateDrivers/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateBenefitFinancial(id, payload) {
    let endpoint = 'valueProps/updateFinancials/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateBenefitImp(id, payload) {
    let endpoint = 'valueProps/updateImprovement/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateBenefitCurrentEffort(id, payload) {
    let endpoint = 'valueProps/updateMetricCurrentEffort/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public getValuePropPhasing(valuepropid: string, id: string): Observable<APIResult<ValuePropPhasing>> {
    let endpoint = 'valueProps/readMetricPhasing/' + valuepropid + '/valuePropMetricId/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public updateValuePropPhasing(payload, id) {
    let endpoint = 'valueProps/updateMetricPhasing/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateMetricActive(id, payload) {
    let endpoint = 'valueProps/updateMetricActive/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public bulkUpdateSalesforceValueProps() {
    let endpoint = 'valueProps/bulkUpdateSalesforceValueProps';
    return this.commonService.putAPIService(endpoint, null);
  }
  public updateMetricsActive(id, payload) {
    let endpoint = 'valueProps/updateMetricsActive/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public getValuePropShares(id: string): Observable<APIResult<{ shares: Share[] }>> {
    let endpoint = 'valueProps/readShares/' + id + '?is_collaboration=0';
    return this.commonService.getAPIService(endpoint);
  }
  public getUsers(acc_id) {
    let endpoint = 'account/users/' + acc_id;
    return this.commonService.getAPIService(endpoint);
  }
  public getShareRoles(): Observable<APIResult<SharedRole[]>> {
    let endpoint = 'fact/shareRoles';
    return this.commonService.getAPIService(endpoint);
  }
  public saveValuePropShare(id, payload) {
    let endpoint = 'valueProps/share/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getValuePropAllBenefitsTurboSummedByCategory(id: string | number, showAll = 3, orderFlag = 0, filter = 'value_category', connected = false) {
    const connectedQuery = connected ? '?connected_objects=true&full_format=0' : '?full_format=0';
    const endpoint = `valueProps/metricsByBenefitType/${id}/showAll/${showAll}/summedBenefits/1/orderFlag/${orderFlag}/filterBy/${filter}` + connectedQuery;
    return this.commonService.getAPIService(endpoint);
  }

  public cloneValueProp(id, payload) {
    let endpoint = 'valueProps/cloneValueProp/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public lockValueProp(id, payload) {
    let endpoint = 'valueProps/updateClosed/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getValuePropChangeLog(id) {
    let endpoint = 'valueProps/changeLog/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getValuePropCosts(id) {
    let endpoint = 'valueProps/costs/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateCostTitle(id, payload) {
    let endpoint = 'valueProps/updateCost/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getCostDetail(id, cost_id) {
    let endpoint = 'valueProps/readCost/' + id + '/costId/' + cost_id;
    return this.commonService.getAPIService(endpoint);
  }

  public calculateAdvancedAccrualCosts(value_prop_id, requestPayload) {
    let endpoint = 'valueProps/calculateAdvancedAccrualCosts/' + value_prop_id;
    return this.commonService.postAPIService(endpoint, requestPayload);
  }

  //scenario
  public getValuePropCompany(id) {
    let endpoint = 'valueProps/company/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateCompany(id, payload) {
    let endpoint = 'companies/updateCompany/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getTCO(id, all_metric) {
    let str = '';
    if (all_metric == 1) {
      str = '/includeAll/' + all_metric;
    }
    let endpoint = 'valueProps/TCONew/' + id + str;
    return this.commonService.getAPIService(endpoint);
  }

  public getCompetitiveTCO(value_prop_id: string, all_metric: number): Observable<APIResult<CompetitiveTCO>> {
    let str = '';
    if (all_metric == 1) {
      str = '/includeAll/' + all_metric;
    }

    let endpoint = `/valueProps/competitiveTCO/${value_prop_id}` + str;
    return this.commonService.getAPIService(endpoint);
  }

  public getNotes(id) {
    let endpoint = 'valueProps/readNotes/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public saveNote(id, payload) {
    let endpoint = 'valueProps/note/create/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public deleteNote(value_prop_id, id) {
    let endpoint = 'valueProps/note/delete/' + value_prop_id + '/valuePropNoteID/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public updateNote(id, payload) {
    let endpoint = 'valueProps/note/update/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getCompetitors(id) {
    let endpoint = 'valueProps/competitors/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getCashflow(id) {
    let endpoint = 'valueProps/readCashFlow/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getSituation(id: string | number): Observable<APIResult<Situation>> {
    let endpoint = 'valueProps/situation/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public readAssetLanguageTemplates(account_id) {
    let endpoint = 'account/readAssetLanguageTemplates/' + account_id;
    return this.commonService.getAPIService(endpoint);
  }
  public getOpportunityId(id) {
    let endpoint = 'valueProps/opportunityId/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getAccrualTypes(): Observable<APIResult<AccrualType[]>> {
    let endpoint = 'fact/accrualTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public getAvailableCosts(id) {
    let endpoint = 'valueProps/availableCosts/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getCompetitorList(valuePropId) {
    let endpoint = `valueProps/costCompetitors/${valuePropId}`;
    return this.commonService.getAPIService(endpoint);
  }

  public getTerms(id) {
    let endpoint = 'account/readAccountTerms/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public addCost(id, payload) {
    let endpoint = 'valueProps/addCost/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public editCost(id, payload) {
    let endpoint = 'valueProps/updateCost/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateAdvanced(id, payload) {
    let endpoint = 'valueProps/valuePropAdvanced/update/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public deleteValuePropCosts(propcostid, id) {
    let endpoint = '/valueProps/cost/delete/' + propcostid + '/valuePropCostId/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public getCustomerTypes() {
    let endpoint = 'fact/customerTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public getBaselineTypes(): Observable<APIResult<BaselineType[]>> {
    let endpoint = 'fact/baselineTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getDrivers(id: string): Observable<APIResult<{ drivers: AssumptionDriver[] }>> {
    let endpoint = 'valueProps/drivers/' + id + '/scratchpadDetails/0';
    return this.commonService.getAPIService(endpoint);
  }

  public getKeyAssumptions(id): Observable<APIResult<KeyAssumptionsData>> {
    let endpoint = 'valueProps/keyAssumptions/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getKeyAssumptionDrivers(id) {
    let endpoint = 'valueProps/keyAssumptionDrivers/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getFinancials(id: string): Observable<APIResult<{ financials: AssumptionFinancial[] }>> {
    let endpoint = 'valueProps/financials/' + id + '/scratchpadDetails/0';
    return this.commonService.getAPIService(endpoint);
  }
  public getScartchpadUnits(id: string): Observable<APIResult<{ units: AssumptionScratchpad[] }>> {
    let endpoint = 'valueProps/readScratchpadDistinctUnits/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public getScalers(id, includeDns) {
    let endpoint = 'valueProps/availableScalers/' + id + '/angularDev/1/includeDns/' + includeDns + '/testing/0';
    return this.commonService.getAPIService(endpoint);
  }
  public createScalers(id, payload) {
    let endpoint = 'valueProps/scaler/create/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getInitiatives(id) {
    let endpoint = 'valueProps/readInitiatives/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public updateDriverFactor(id, payload) {
    let endpoint = 'valueProps/updateDrivers/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public getSourceTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/sourceTypes?display_only=1';
    return this.commonService.getAPIService(endpoint);
  }
  public updateFinancialFactor(id, payload) {
    let endpoint = 'valueProps/updateFinancials/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateScratchpadUnit(id, payload) {
    let endpoint = 'valueProps/scratchpadUnit/update/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public saveImpactTypes(value_prop_id, payload) {
    let endpoint = 'valueProps/impactCreate/' + value_prop_id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public updateImpactTypes(value_prop_id, payload) {
    let endpoint = 'valueProps/updateImpact/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public deleteImpact(value_prop_id, id) {
    let endpoint = 'valueProps/deleteImpact/' + value_prop_id + '/impactID/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public readSolutionList(params: string): Observable<APIResult<Solution[]>> {
    let endpoint = 'valueProps/solutions/' + params;
    return this.commonService.getAPIService(endpoint);
  }

  public saveValuePropsBenefits(id, payload) {
    let endpoint = 'valueProps/oneTimeMetric/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getAvailableSituations(payload) {
    let endpoint = 'valueProps/availableSituations/' + payload;
    return this.commonService.getAPIService(endpoint);
  }

  public saveSituations(id, payload) {
    let endpoint = 'valueProps/situation/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public addNewCustomer(id, payload) {
    let endpoint = 'valueProps/customerShare/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateValuePropStatus(id, payload) {
    let endpoint = 'valueProps/updateValuePropStatus/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateValuePropGroupStatus(value_prop_group_id, payload) {
    let endpoint = 'valueProps/updateValuePropGroupStatus/' + value_prop_group_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public readDriverFactorScratchPad(params: string): Observable<APIResult<ReadScratchpadResult>> {
    let endpoint = 'valueProps/readScratchpad/' + params;
    return this.commonService.getAPIService(endpoint);
  }
  public getScoreCard(value_prop_id: string): Observable<APIResult<ScoreCard>> {
    let endpoint = 'valueProps/readScoreCard/' + value_prop_id;
    return this.commonService.getAPIService(endpoint);
  }

  public readScratchpadTotal(paramsID, payload) {
    let endpoint = 'valueProps/readScratchpadTotal/' + paramsID;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getValuePropMetric_DriverFactorSubTypes(valuePropId, valuePropMetricId) {
    let endpoint = 'valueProps/readMetricDriverFactorSubtypes/' + valuePropId + '/valuePropMetricID/' + valuePropMetricId;
    return this.commonService.getAPIService(endpoint);
  }
  public scratchpadOperatorTypes(): Observable<APIResult<ScracthpadOperatorType[]>> {
    let endpoint = 'fact/scratchpadOperatorTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public createValuePropMetric_DriverFactorSubTypes(valuePropId, payload) {
    let endpoint = 'valueProps/metricDriverFactorSubtypes/create/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public updateValuePropMetric_DriverFactorSubTypes(payload, valuePropId) {
    let endpoint = 'valueProps/metricDriverFactorSubtypesEnabled/update/' + valuePropId;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public deleteValuePropMetric_DriverFactorSubTypes(valuePropMetricId) {
    let endpoint = 'valueProps/metricDriverFactorSubtype/delete/valuePropMetricID/' + valuePropMetricId;
    return this.commonService.deleteAPIService(endpoint);
  }
  public updateScratchpad(id, payload) {
    let endpoint = 'valueProps/scratchpad/update/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getDriverGrowth(param1: string, param2: string): Observable<APIResult<ValuePropPhasing>> {
    let endpoint = 'valueProps/readMetricPhasing/' + param1 + '/valuePropMetricId/' + param2;
    return this.commonService.getAPIService(endpoint);
  }

  public updateMetricAltName(id, payload) {
    let endpoint = 'valueProps/updateMetricAltName/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateMetricAltDescription(id, payload) {
    let endpoint = 'valueProps/updateMetricAltDescription/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateMetricAltFeatures(id, payload) {
    let endpoint = 'valueProps/updateMetricAltFeatures/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateValueCategoryOverride(id, payload) {
    let endpoint = 'valueProps/groupingOverride/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateTCOName(id, payload) {
    let endpoint = 'valueProps/updateTcoName/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateMetricNote(id, payload) {
    let endpoint = 'valueProps/updateMetricNote/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteMetricNote(param1, param2) {
    let endpoint = 'valueProps/deleteMetricNote/' + param1 + '/' + param2;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateDrivingPhase(id, payload) {
    let endpoint = 'valueProps/updateDriverPhasing/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveBenefitFeedback(payload, id) {
    let endpoint = 'solutions/benefits/feedback/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public readFeedback(id: string): Observable<APIResult<FeedbackResponse>> {
    let endpoint = 'solutions/benefits/readFeedback/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public readAvailableSituations(id: string): Observable<APIResult<ValuePropSituation[]>> {
    let endpoint = 'valueProps/situations/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateValuePropsBenefits(id, payload) {
    let endpoint = 'valueProps/updateMetricDetail/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteCustomBenefit(param1, param2) {
    let endpoint = 'valueProps/deleteMetricDetail/' + param1 + '/metricId/' + param2;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateShare(id, payload) {
    let endpoint = 'valueProps/updateShare/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteShareUser(param1, param2) {
    let endpoint = 'valueProps/deleteShare/' + param1 + '/shareID/' + param2;
    return this.commonService.deleteAPIService(endpoint);
  }

  public readValuePropAssetTemplates(valuePropIdSearchLimit: string | number): Observable<APIResult<AssetTemplate[]>> {
    let endpoint = 'asset/valuePropTemplates/' + valuePropIdSearchLimit + '?include_deleted_templates=true';
    return this.commonService.getAPIService(endpoint);
  }

  public readValuePropAssetTemplatesForWfs(vpId: string | number, wfId: string = null): Observable<APIResult<AssetTemplate[]>> {
    let endpoint = '';
    wfId ? (endpoint = `asset/valuePropTemplates/${vpId}?account_workflow_id=${wfId}`) : (endpoint = `asset/valuePropTemplates/${vpId}`);
    return this.commonService.getAPIService(endpoint);
  }

  public readValuePropAssetTemplatesWithoutId() {
    let endpoint = 'asset/valuePropTemplates';
    return this.commonService.getAPIService(endpoint);
  }

  public createProjects(valuePropId, payload) {
    let endpoint = 'valueProps/projects/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public readDeploymentSchedule(valuePropId) {
    let endpoint = 'valueProps/deploymentSchedule/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public createDeploymentSchedule(valuePropId, payload) {
    let endpoint = 'valueProps/deploymentSchedule/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public readNetPercents(payload) {
    let endpoint = 'valueProps/netPercents/';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public readCaseStudies(valuePropId) {
    let endpoint = 'valueProps/caseStudies/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readAssets(valuePropId) {
    let endpoint = 'valueProps/readAssetsByValuePropId/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readCollateral(valuePropId): Observable<APIResult<ValuePropCollateral[]>> {
    let endpoint = 'valueProps/readCollateralByValuePropId/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readAvailableScalers(valuePropId) {
    let endpoint = 'valueProps/availableScalers/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readAvailableScalersNoTrans(valuePropId) {
    let endpoint = '/valueProps/availableScalers/' + valuePropId + '/angularDev/1/includeDns/0/testing/0';
    return this.commonService.getAPIService(endpoint);
  }

  public readUserValuePropsPlain() {
    let endpoint = 'valueProps/UserValueProps';
    return this.commonService.getAPIService(endpoint);
  }

  public saveAnswers(valuePropId, payload) {
    const endpoint = 'valueProps/answers/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public readProjectSuggestions(valuePropId) {
    const endpoint = 'valueProps/projectSuggestions/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readCaseStudyByValuePropMetricId(valuePropId: string, valuePropMetricId: string): Observable<APIResult<MetricCaseStudy[]>> {
    const endpoint = 'valueProps/metricCaseStudies/' + valuePropId + '/valuePropMetricId/' + valuePropMetricId;
    return this.commonService.getAPIService(endpoint);
  }

  public putVMWareQ1(value_prop_id, payload) {
    let endpoint = 'valueProps/VMWq1/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public putVMWareQ2(value_prop_id, payload) {
    let endpoint = 'valueProps/VMWq2/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateMissionStatement(id, payload) {
    let endpoint = 'valueProps/updateMissionStatement/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateAllFactors(value_prop_id, payload) {
    let endpoint = 'valueProps/updateFactorsInBulk/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateAllScratchpadFactors(value_prop_id, payload) {
    let endpoint = 'valueProps/updateScratchpadUnitsInBulk/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getDiscoveryQuestions(id: string, sectionId?: string): Observable<APIResult<(DiscoveryQuestion & { answer: string })[]>> {
    const endpoint = 'valueProps/discoveryQuestions/' + id;
    return this.commonService.getAPIService(endpoint).pipe(
      map((r: { result: DiscoveryQuestion[] }) => ({
        result: r.result
          .filter((question) => !sectionId || question.sections.some((section) => section.id === sectionId))
          .map((question) => ({
            ...question,
            answer: question.answer || question.placeholder_text,
          })),
      }))
    );
  }

  public putDiscoveryQuestions(id, payload) {
    let endpoint = 'valueProps/discoveryQuestions/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public postDiscoveryQuestionNotes(id: string, payload: DiscoveryQuestionNotePayload): Observable<DiscoveryQuestionNoteResponse> {
    let endpoint = 'valueProps/discovery-question-note/' + id;
    return this.commonService.postAPIService(endpoint, payload).pipe(pluck('result'));
  }

  public putDiscoveryQuestionNotes(id: string, payload: DiscoveryQuestionNotePayload) {
    let endpoint = 'valueProps/discovery-question-note/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteDiscoveryQuestionNotes(noteId: string, vpId: string) {
    let endpoint = `valueProps/discovery-question-note/delete/${vpId}/discovery-question-note-id/` + noteId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public createCustomerShare(valuePropId, payload) {
    const endpoint = 'valueProps/customerShare/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public UpdateBenefitsCurrentFuture(value_prop_id, payload) {
    let endpoint = 'valueProps/updateImpactFromEffort/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public UpdateBenefitsActive(value_prop_id, payload) {
    let endpoint = 'valueProps/updateMetricActiveByValueCategory/' + value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteValuePropGroup(value_prop_group_id: string | number): Observable<APIResult<SuccessFailResponse>> {
    let endpoint = 'valueProps/valuePropGroup/' + value_prop_group_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public putDragDropBenefitCategory(vpid, payload) {
    let endpoint = 'valueProps/valueCategorySequence/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public uploadFinalDeliverable(vpid, payload) {
    let endpoint = 'valueProps/finalDeliverable/upload/' + vpid;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public readFinalDeliverables(valuePropId) {
    const endpoint = 'valueProps/finalDeliverable/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteFinalDeliverable(value_prop_id, id) {
    let endpoint = 'valueProps/delete/finalDeliverable/' + value_prop_id + '/deliverableId/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateFinalDeliverable(valuePropId, payload) {
    let endpoint = 'valueProps/finalDeliverable/update/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public uploadValuePrompter(vpid, payload) {
    let endpoint = 'valueProps/valuePrompter/upload/' + vpid;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public readValuePrompter(valuePropId) {
    const endpoint = 'valueProps/valuePrompter/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteValuePrompter(value_prop_id, id) {
    let endpoint = 'valueProps/delete/valuePrompter/' + value_prop_id + '/prompterId/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateValuePrompter(valuePropId, payload) {
    let endpoint = 'valueProps/valuePrompter/update/' + valuePropId;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public valuePropGetFactorGroups(vpid, assumption, ignoreImprovements = 1): Observable<APIResult<AssumptionFactorGroups>> {
    const endpoint = 'valueProps/factorGroups/' + vpid + '/keyAssumptions/' + assumption + '/ignoreImprovements/' + ignoreImprovements;
    return this.commonService.getAPIService(endpoint);
  }

  public putRepSimpleFactorGroups(vpid, payload) {
    let endpoint = 'valueProps/updateAccountSpecificFactorsInBulk/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public putFactorsInBulk(vpid, payload) {
    let endpoint = 'valueProps/updateFactorsInBulk/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public quickFillGetBenefits(vpid: string): Observable<APIResult<BenefitTurboSummedResponse>> {
    const endpoint = 'valueProps/turboRead/' + vpid + '/showAll/3/summedBenefits/1/orderFlag/0/filterBy/value_category';
    return this.commonService.getAPIService(endpoint);
  }

  public quickFillPut(vpid, payload) {
    let endpoint = 'valueProps/updateFactorsAndMetrics/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public clearOverrides(vpid, payload) {
    let endpoint = 'valueProps/resetFactorPhasing/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }
}

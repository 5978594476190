<form name="formAddEditBenefitType" #formAddEditBenefitType="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row" *ngIf="mode === 'view'">
          <div class="col-md-12">
            <span class="read-only">{{ trans.trans.read_only.value }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditBenefitType.controls.name">
            <label for="name" class="sidebar_label">{{ trans.trans.name.value }}</label>
            <input
              type="text"
              class="form-control"
              ret-data="Name"
              [(ngModel)]="selectedElem.name"
              name="name"
              [placeholder]="trans.trans.name.value"
              required
              [disabled]="mode === 'view'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditBenefitType.controls.description">
            <label for="description" class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" ret-data="Description" [(ngModel)]="selectedElem.description" rows="4" class="form-control" [disabled]="mode === 'view'"></textarea>
          </div>
        </div>

        <!-- add custom asset tags here  -->
        <ng-container>
          <div class="col-md-6">
            <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
            <br />
            <p-inputSwitch [(ngModel)]="selectedElem.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
          </div>

          <div class="col-md-6"></div>
          <!-- Benefit Type Tag -->
          <div
            *ngIf="selectedElem.has_tag"
            class="col-md-6"
            appNGError
            [elemControl]="formAddEditBenefitType.controls.valueTag"
            [ngClass]="{ 'field-error': selectedElem.nameTag === selectedElem.tag }"
          >
            <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.benefitValueTag.value }}</label>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="selectedElem.tag"
              name="valueTag"
              ret-data="Value Tag"
              maxlength="255"
              appSpaceToUnderscore
              appSpecialCharacterValidator
              [required]="selectedElem.has_tag"
            />
            <span *ngIf="selectedElem.has_tag" class="hint light-h">{{ selectedElem.tag ? (selectedElem.tag | assetTagFormat) : (trans.trans.value.value | assetTagFormat) }}</span>
            <span class="input-error-warn" *ngIf="formAddEditBenefitType.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class='btn_container' [ngClass]="{ 'xplatform-btn-container-adjustment': isUsingXPlatformCrm }">
      <div class="row">
        <div class="col-md-12">
          <input type="hidden" name="id" [(ngModel)]="selectedElem.id" />
          <button
            *ngIf="mode === 'add'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.save.value"
            [disabled]="!formAddEditBenefitType.valid"
            [loading]="isLoading"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
            (click)="insertBenefitType(formAddEditBenefitType)"
          ></button>
          <button
            *ngIf="mode === 'edit'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.update.value"
            [loading]="isLoading"
            [disabled]="!formAddEditBenefitType.valid"
            (click)="updateBenefitType(formAddEditBenefitType)"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
          ></button>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.are_you_sure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="close()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</form>

<app-benefit-menu *ngIf="!bI.benefitsLoader" [step2]="true"></app-benefit-menu>
<dl-progressbar *ngIf="bI.benefitsLoader" class="dl_progress" [showValue]="true" [value]="'Loading Benefits'" mode="indeterminate"></dl-progressbar>

<p-menu #inlinestatus appendTo="body" [popup]="true" [model]="bI.inlinestatus" [styleClass]="'style-2022'"></p-menu>

<ng-container *ngIf="!bI.benefitsLoader">
  <div
    *ngFor="let benefitType of bI.benefitsListByType | filterBenefitTypeByStatus: bI.showActive:bI.showNA:bI.showSoft"
    id="drag-container"
    cdkDropList
    (cdkDropListDropped)="drop($event, benefitType.vcs)"
    [cdkDropListDisabled]="bI.locked || !canEdit || bI.group_selected !== 'value_category'"
  >
    <h3 *ngIf="benefitType.id === '1'">{{ trans.trans?.benefits.value }} - {{ trans.trans?.financial.value }}</h3>
    <h3 *ngIf="benefitType.id !== '1'">{{ trans.trans?.benefits.value }} - {{ trans.trans?.nonFinancial.value }}: {{ benefitType.name }}</h3>
    <div *ngFor="let benefitCategory of benefitType.vcs" cdkDrag [cdkDragData]="benefitCategory" [cdkDragPreviewClass]="'default-dnd-theme'">
      <div class="panel panel-white panel-dashed" [ngClass]="bI.locked ? 'disable_drag' : 'dragObjects'" *ngIf="bI.showGroup(benefitCategory.metrics)">
        <div class="panel-heading simple-benefit-line" cdkDragHandle style="height: inherit !important; background-color: #f5f5f5; padding: 0px !important">
          <div class="row">
            <div class="col-xs-12" style="padding-right: 30px">
              <table style="table-layout: fixed; width: 100%">
                <tr>
                  <td width="40px" style="width: 40px !important; vertical-align: top">
                    <div (click)="bI.expandCat(benefitCategory)"><i class="fa fa-chevron-right clickable" *ngIf="!benefitCategory.expand" style="margin-right: 5px"></i></div>
                    <div (click)="bI.expandCat(benefitCategory)"><i class="fa fa-chevron-down clickable" *ngIf="benefitCategory.expand" style="margin-right: 5px"></i></div>
                  </td>
                  <td width="100%">
                      <a class="title_benefits clickable" (click)="bI.expandCat(benefitCategory)">
                      {{ benefitCategory.name }}
                      </a>
                      <span
                        *ngIf="benefitCategory.description"
                        class="material-icons-outlined tooltip-custom info-icon"
                        [matTooltip]="benefitCategory.description"
                        matTooltipPosition="above"
                        container="body"
                      >
                        info
                      </span>
                      <i 
                        (click)="editValueCategory(benefitCategory)" 
                        *ngIf="!bI.locked && canEdit && benefitCategory.id !== '0' && !disableEdits" 
                        class="fa fa-pencil clickable" 
                        style="margin-left: 10px"
                      ></i>
                      <div *ngIf="benefitCategory.edit_value_category" class="input-group" style="width: 100%" [matTooltip]="trans.trans.ch_renameValueCategory.value" matTooltipPosition="above">
                        <input type="text" name="value_category" [(ngModel)]="benefitCategory.alt_name" class="form-control" />
                        <div class="input-group-btn">
                          <span
                            (click)="closeValueCategoryEdit(benefitCategory)"
                            class="clickable input-group-addon action-button"
                          >
                            <i class="fa fa-times"></i>
                          </span>
                          <span
                            (click)="updateValueCategoryName(benefitCategory)"
                            class="clickable input-group-addon action-button"
                          >
                            <i class="fa fa-save"></i>
                          </span>
                        </div>
                      </div>
                      <br/>
                    <small class="benefit-status" style="color: green !important; margin: 5px">{{ benefitCategory.active_count }} {{ trans.trans.active.value }}</small>
                    <small class="benefit-status" style="color: red !important; margin: 5px">{{ benefitCategory.na_count }} {{ trans.trans.na.value }}</small>
                    <small class="benefit-status" style="color: orange !important; margin: 5px" *ngIf="!bI.feature46">{{ benefitCategory.soft_count }} {{ trans.trans.soft.value }}</small>
                  </td>
                  <td style="width: 230px">
                    <img *ngIf="benefitCategory.loading" class="loader pull-right" src="{{ bI.fullImagePath }}" />
                    <div class="input-group-btn" *ngIf="!benefitCategory.loading && canEdit && bI.group_selected === 'value_category'">
                      <a (click)="bI.funcSetBulkAuto(benefitCategory, 1)" *ngIf="!bI.locked" class="btn btn-success btn-sm a-white">{{ trans.trans.active.value }}</a>
                      <a (click)="bI.funcSetBulkAuto(benefitCategory, 0)" *ngIf="!bI.locked" class="btn btn-danger btn-sm a-white">{{ trans.trans.na.value }}</a>
                      <a (click)="bI.funcSetBulkAuto(benefitCategory, 3)" *ngIf="!bI.locked && !bI.feature39" class="btn btn-warning btn-sm a-white">{{
                        trans.trans.soft.value
                      }}</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="panel-body" *ngIf="benefitCategory.expand">
          <div class="table-responsive col-sm-12 soln-table" style="padding: 0px">
            <table class="table custom-table benefits-simple simple-benefit-line">
              <thead>
                <tr>
                  <th width="120px" style="white-space: nowrap; width: 120px !important">{{ trans.trans.status.value }}</th>
                  <th style="width: 100%">{{ trans.trans.benefits.value }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let benefit of benefitCategory.metrics | filterBenefits: bI.menuFilter:'metric_name' | filterStatus: bI.showActive:bI.showNA:bI.showSoft; let i = index"
                >
                  <tr class="grid-row" [ngClass]="{ row_selected: benefit.showDetails }">
                    <td *ngIf="bI.showBatchUpdateCheckboxes">
                      <p-checkbox name="checkbox{{ i }}" [binary]="true" [(ngModel)]="benefit.updateBatch"></p-checkbox>
                    </td>

                    <td
                      class="benefit-status benefitStatusElement"
                      *ngIf="!bI.showBatchUpdateCheckboxes"
                      [matTooltip]="bI.locked ? '' : trans.trans.benefit_status.value"
                      matTooltipPosition="above"
                    >
                      <a
                        (click)="bI.locked || !canEdit ? null : bI.openActiveTypesPmenu(benefit, inlinestatus, $event)"
                        class="dashedLink benefitStatusElement"
                        data-toggle="tooltip"
                        title="Change Benefits Status"
                      >
                        <span class="activeStatusType benefitStatusElement" [ngStyle]="{ 'background-color': bI.getBenefits(benefit.active) }"></span>
                      </a>
                    </td>

                    <td style="width: 100% !important" *ngIf="bI.inputOpened && benefit.changeActive && canEdit" class="benefitStatusElement">
                      <form class="form-inline benefitStatusElement">
                        <div class="editable-controls form-group benefitStatusElement input-group">
                          <select
                            class="form-control active-type benefitStatusElement"
                            name="benefit_active_{{ benefit.id }}"
                            [(ngModel)]="benefit.active"
                            (change)="bI.statusChange(benefit)"
                            style="width: 150px"
                          >
                            <option value="" class="benefitStatusElement" disabled>{{ trans.trans.choose.value }}</option>
                            <option [disabled]="bI.locked" *ngFor="let activeType of bI.activeTypes" [value]="activeType.value" class="benefitStatusElement">
                              {{ activeType.name }}
                            </option>
                          </select>
                          <div class="input-group-btn">
                            <a class="btn btn-danger" (click)="bI.inputOpened = !bI.inputOpened"><i class="fa fa-times"></i></a>
                          </div>
                        </div>
                      </form>
                    </td>

                    <td class="benefit-status">
                      <a class="xf-benefit-name title_benefits clickable" (click)="benefit.showDetails = !benefit.showDetails">
                        <i class="fa fa-chevron-right xf-black-chevron" *ngIf="!benefit.showDetails"></i>
                        <i class="fa fa-chevron-down xf-black-chevron" *ngIf="benefit.showDetails"></i>
                        {{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name }}
                      </a>
                      <span *ngIf="benefit.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="benefit.description" matTooltipPosition="above">
                        info
                      </span>
                    </td>
                  </tr>
                  <tr [ngClass]="{ row_selected: benefit.showDetails }" *ngIf="benefit.showDetails">
                    <td colspan="4">
                      <p-sidebar
                        *ngIf="feature57"
                        [visible]="feature57 && !!benefit"
                        styleClass="keywest_sidebar p-sidebar-xl"
                        position="right"
                        (onHide)="benefit.showDetails = false"
                      >
                        <div class="sidebar_wrapper">
                          <div class="sidebar_header">
                            Benefit Details
                            <a
                              *ngIf="+bI.valueProp.closed === 0"
                              (click)="benefitDetail.funcLiveSave('all', '')"
                              class="btn btn_customizable pull-right"
                              style="margin-top: -4px; margin-right: 30px"
                              >Save</a
                            >
                          </div>

                          <div class="sidebar_container">
                            <div class="keywest_panel">
                              <app-benefit-detail
                                #benefitDetail
                                [permissions]="bI.permissions"
                                [shareView]="bI.shareView"
                                [embedded]="true"
                                [contextualHelp]="bI.contextualHelp"
                                [valueProp]="bI.valueProp"
                                [selectedBenefit]="benefit"
                                [canEdit]="canEdit"
                                (closeBenefitDetail)="benefit.showDetails = false"
                              ></app-benefit-detail>
                            </div>
                          </div>
                        </div>
                      </p-sidebar>
                      <app-benefit-detail
                        *ngIf="!feature57"
                        [permissions]="bI.permissions"
                        [shareView]="bI.shareView"
                        [embedded]="true"
                        [contextualHelp]="bI.contextualHelp"
                        [valueProp]="bI.valueProp"
                        [selectedBenefit]="benefit"
                        [canEdit]="canEdit"
                        (closeBenefitDetail)="benefit.showDetails = false"
                      ></app-benefit-detail>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div *ngIf="bI.benefitsLoader" class="col-sm-12 m-t-md text-center">
            <p class="text-center">
              <img class="loader" [src]="bI.fullImagePath" />
            </p>
          </div>
          <div *ngIf="(bI.benefitsList === null || bI.benefitsList.length === 0) && !bI.benefitsLoader" class="m-t-md col-sm-12">
            <div class="searchTableContainer text-center">
              <h4>{{ trans.trans.NoBenefitsFound.value }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-value-prop-flow-buttons [next]="showContinue" *ngIf="bI.view === 'rep-simple'" [moveToBottom]="!crm" (goNext)="nextStep()" (goBack)="goBack()"></app-value-prop-flow-buttons>

<p-sidebar *ngIf="bI.showDiscoveryQuestions" [(visible)]="bI.showDiscoveryQuestions" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-discovery-questions
    [locked]="!bI.valueProp.vp_can_edit"
    (callback)="bI.showDiscoveryQuestions = !bI.showDiscoveryQuestions"
    [showTranslate]="showTranslate"
    [valuePropId]="bI.valueProp.id"
    [valueProp]="bI.valueProp"
  ></app-discovery-questions>
</p-sidebar>
